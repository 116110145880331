<div class="devices-page">
    <div class="devices-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'devices.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ts-button text="{{ 'devices.addNewThirdParty' | translate}}" type="primary medium blue"
                               (click)="onActionClick('thirdparty')">
                    </ts-button>
                    <ts-button text="{{ 'devices.addNewVirtual' | translate}}" type="primary medium blue"
                               (click)="onActionClick('virtual')">
                    </ts-button>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="ts-vapor ts-layout layout-container">

                <ts-tree-list #devicesList [data]="unipis" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" [showSearchBar]="false"
                              [enablePaging]="false" [hideActionsColumn]="true" [showBorders]="false"
                              [showRowLines]="true" [wordWrapEnabled]="true" [height]="devicesListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-rowIndex let-level="level">
                        <span *ngIf="tplKey === 'tplActions'">

                            <ts-tree-list-button icon="plus" title="Add"
                                                 (click)="onActionClick('new',data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>
</div>


<!-- <ng-template #newThirdPartyDeviceDialog ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="openThirdPartyDeviceDialog()">
        <i class="fa fa-plus"></i>
        <span> {{ 'devices.addNewThirdParty' | translate}}</span>
    </div>
</ng-template>
<ng-template #newVirtualDeviceDialog ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="openVirtualDeviceDialog()">
        <i class="fa fa-plus"></i>
        <span> {{ 'devices.addNewVirtual' | translate}}</span>
    </div>
</ng-template> -->

<ng-template #newDeviceTemplate>
    <app-new-device-drawer #devicesDrawer [deviceType]="deviceType" [unipi]="selectedUnipi"></app-new-device-drawer>
</ng-template>

<ng-template #newThirdPartyDeviceTemplate>
    <app-new-device-drawer #devicesDrawer [deviceType]="deviceType"></app-new-device-drawer>
</ng-template>

<ng-template #newVirtualDeviceTemplate>
    <app-new-device-drawer #devicesDrawer [deviceType]="deviceType"></app-new-device-drawer>
</ng-template>

<ng-template #devicesDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar type="medium" exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="saveDevice()"
                   [disableExit]="!devicesDrawer?.formValid">
        <ts-button type="secondary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>