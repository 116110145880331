import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class Utility {

    cloneArray(array: any) {
        if (array) {
            return JSON.parse(JSON.stringify(array));
        }

        return [];
    }

    cloneObject(object: any) {
        return JSON.parse(JSON.stringify(object));
    }

    static formatItDecimal(value: number, decimals: number) {
        return new Intl.NumberFormat('it-IT', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value);
    }

    formattedDate(date: Date) {
        return date.toLocaleString(moment.locale(localStorage.getItem('lang')), {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        });
    }
}