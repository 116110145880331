import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  private readonly configUrl = 'configuration/cms-config.json';
  private configSettings: any = null;

  constructor(private http: HttpClient) {}    

  get settings() {
    return this.configSettings;
  }

  public async load(): Promise<any> {
      console.log(`Loading configuration from ${this.configUrl}`);
      return new Promise((resolve, reject) => {
      this.http.get(this.configUrl).subscribe((response: any) => {
          this.configSettings = response;

          if (!this.configSettings) {
              this.configSettings = environment;
          }
          resolve(true);
      }, (error: any) => {
        console.error(`Error reading configuration file: ${error.message}`);
        if (!this.configSettings) {
            this.configSettings = environment;
        }
        resolve(true);
    });
  });
  }
}