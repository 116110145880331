import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../../../services/tag.service';
import { TagInstance } from '../../../models/tag.model';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { DeviceInstance } from '../../../models/device.model';
import { Drawer, FontService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { TagsDrawerComponent } from '../../ui/tags-drawer/tags-drawer.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';


@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TagsComponent implements OnInit {
    isAdmin = false;

    companies: CompanyInstance[];
    devices: DeviceInstance[];
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    selectedTag: TagInstance;
    tagsList: TagInstance[];
    tmpTags: TagInstance[];
    columns: TsTreeListColumn[] = [];

    tagListHeight = '100vh';
    private _subscriptions: Subscription[] = [];


    @ViewChild('tagList', { static: false, read: ElementRef }) tagListRef!: ElementRef;
    @ViewChild('editTagTemplate', { static: false }) editTagTemplate: TemplateRef<any>;
    @ViewChild('newTagTemplate', { static: false }) newTagTemplate: TemplateRef<any>;
    @ViewChild('tagDrawerBottomTemplate', { static: false }) tagDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('tagsDrawer', { static: false }) tagsDrawer: TagsDrawerComponent;


    constructor(
        private _company: CompanyService,
        private _device: DeviceService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _popup: PopupService,
        private _drawer: Drawer,
        private _ui: UiService,
        private _translate: TranslateService,
        private _tags: TagsService,
        private _font: FontService,
        public _auth: AuthService,
        private _router: Router
    ) {
        this._font.addIcon(faPencil, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {

        await this._auth.loadState();
        this._translate
            .stream([
                'tags.title',
                'devices.columns.name',
                'tags.columns.n_errors',
                'orders-list.search'
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['tags.title']);
                this._sidebar.setSelected('tags');

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    allowFiltering: false,
                    allowEditing: false,
                    allowSorting: false,
                    alignment: 'left',
                    width: '100%'
                };

                this.columns = [{
                    ...defaultColumn,
                    dataField: 'name',
                    dataType: 'string',
                    caption: translations['devices.columns.name'],
                    allowFiltering: true,
                    headerPlaceholder: translations['orders-list.search']
                }, {
                    ...defaultColumn,
                    dataField: 'Errors.length',
                    dataType: 'string',
                    caption: translations['tags.columns.n_errors'],
                },
                {
                    ...defaultColumn,
                    caption: '',
                    width: 135,
                    allowFiltering: false,
                    allowSorting: false,
                    cellTemplate: 'tplActions',
                    alignment: 'center',
                }];

            });
        this.isAdmin = this._auth.user.role === UserRole.admin;

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });

        this._subscriptions.push(companyChangeSubscription);

        if (this.isAdmin) {
            this.companies = await this._company.getCompanies();
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged(this.selectedCompany);
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged(this.selectedCompany);
            }
        } else {
            this.devices = await this._device.getDevices();
            if (this.devices.length > 0) {
                this.selectedCompany = this.devices[0].Company;
                this.tagsList = await this._tags.getTags(true);
                this.tagsList = this.tagsList.filter((tag) => {
                    return tag.id > 0;
                });
                this.tmpTags = this.clone(this.tagsList);
            }
        }
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.tagListRef) {
            const offsetTop = this.tagListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.tagListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async companyChanged(company: CompanyInstance) {

        this.selectedCompany = company;

        this.selectedCompanyId = this.selectedCompany.id;
        this.tagsList = await this._tags.getTags(true, this.selectedCompany.id);
        this.tagsList = this.tagsList.filter((tag) => {
            return tag.id > 0;
        });

        this.tmpTags = this.clone(this.tagsList);
    }

    createOrUpdateTag(tag?: TagInstance) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (tag) {
            this.selectedTag = tag;
        }

        this._drawer.open({
            title: tag ? tag.name : this._translate.instant('tags.title'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: tag ? this.editTagTemplate : this.newTagTemplate,
            bottomBarTemplate: this.tagDrawerBottomTemplate,
        }).subscribe(() => {
            this._drawer.expand();
        });

    }

    onFormSubmit() {
        const res = this.tagsDrawer.onSubmit();

        const tag_data = {
            ...res,
            companyId: this.selectedCompany.id,
        }

        if (res.id) {
            this.save(tag_data);
        } else {
            tag_data['Errors'] = [];
            this.save(tag_data);
        }

    }

    async save(tag: TagInstance) {
        let res = null;

        try {
            if (tag.id) {
                res = await this._tags.update(tag);
                this.tmpTags = this.tmpTags.map((t) => t.id === res.id ? {...res, Errors: t.Errors} : t);
            } else {
                res = await this._tags.create(tag);
                tag.id = res.id;
                this.tmpTags = [...this.tmpTags, tag]
            }

            this._ui.showNotification(this._translate.instant('tags.saved'), 'check');
        } catch (error) {
            this._ui.showNotification(this._translate.instant('tags.error'), 'error');
            console.log("Error saving Tag.");
            console.log(error)
        } finally {
            this._drawer.close();
        }
    }


    async deleteTag(tag: TagInstance) {

        if (!tag.id) {
            return;
        }

        const popupConfig: PopupConfig = {
            title: this._translate.instant('tags.dialog.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('tags.dialog.text', { prod: tag.name }),
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (btn) => {
            try {
                if (btn === 'primary') {
                    const res = await this._tags.deleteTag(tag.id);

                    if (res) {
                        this.tmpTags = this.tmpTags.filter((t) => t.id !== tag.id);
                        this._ui.showNotification(this._translate.instant('tags.deleted'), 'check');
                    }

                } else {
                    console.log("Delete Canceled")
                    return;
                }
            } catch (error) {
                console.error(error);
                this._ui.showNotification(this._translate.instant('tags.error'), 'error');
            }

        })
    }

    clone(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }

    onCancelDrawerClicked() {
        this._drawer.collapse();
    }
}
