import { AfterContentChecked, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faInfoSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontService, NotificationConfig, NotificationService, NotificationStyle, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupResult, PopupService } from '@vapor/angular-ui';
import { ApiWorkData, ApiWorkPlantData, WorkInstance } from '../../../models/work.model';
import { WorksService } from '../../../services/works.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifyLevel } from '@vapor/angular-ui/popup/popup.models';
import { JsonTranslatorPipe } from '../../../pipes/json-translator.pipe';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-new-work',
    templateUrl: './new-work.component.html',
    styleUrls: ['./new-work.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        NotificationService,
        JsonTranslatorPipe,
    ],
})
export class NewWorkComponent implements OnInit, AfterContentChecked {

    workId: number = undefined;
    editMode: boolean;
    editWork: WorkInstance | undefined = undefined;
    private _subscriptions: Subscription[] = [];
    form: FormGroup;
    dataLoaded: boolean = false;
    companyId: number = null;

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    managedSelectData: { id: number, val: string }[] = [
        { id: 0, val: 'NO' },
        { id: 1, val: 'YES' },
    ];

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _font: FontService,
        private readonly _translate: TranslateService,
        private readonly _notification: NotificationService,
        private readonly _popup: PopupService,
        private readonly _spinner: OverlaySpinnerService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _workService: WorksService,
    ) {
        this._font.addIcon(faArrowLeft, faInfoSquare);
    }

    async ngOnInit(): Promise<void> {
        this.form = this._fb.group({
            code: [null, [Validators.required]],
            description: [null, [Validators.required]],
            managed: [null, [Validators.required, Boolean]]
        });
        this.companyId = parseInt(localStorage.getItem('companyId'));
        this.workId = parseInt(this._route.snapshot.paramMap.get('id'), 10);
        this.editMode = this._route.snapshot.data.editMode;
        this._translate.stream([
            'common.error',
            'products.works.createEdit.loadError',
            'products.works.managedOptions.no',
            'products.works.managedOptions.yes',
            'products.works.workIdError.yes',
        ]).subscribe(async (translations) => {
            this.managedSelectData[0].val = translations['products.works.managedOptions.no'];
            this.managedSelectData[1].val = translations['products.works.managedOptions.yes'];

            if (this.editMode) {
                if (isNaN(this.workId)) {
                    this.createToast("error",translations['common.error'],translations['products.works.managedOptions.no']);
                    await this._router.navigate(['/works']);
                } else {
                    await this.loadData(false);
                }
            }            
        });

        setTimeout(() => this.form.patchValue({managed: this.managedSelectData[1]}), 100);
    }

    async ngAfterContentChecked () {
        if (this.dataLoaded) {
            this.dataLoaded = false;
            this.form.patchValue({
                code: this.editWork.code,
                description: this.editWork.description,
                managed: this.editWork.managed ? this.managedSelectData[1] : this.managedSelectData[0],
            });            
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    private async loadData(showSpinner: boolean) {
        try {
            if (showSpinner) {
                this._spinner.show({ type: "spinner" });
            }
            const works = await this._workService.getWorksForCompany(this.companyId);
            this.editWork = works.find(w => w.id == this.workId);
            if (!this.editWork) {
                this.createToast('check', this._translate.instant('common.error'), this._translate.instant('products.works.createEdit.loadError'));
                await this._router.navigate(['/works']);
            } else {
                this.dataLoaded = true;
            }
        } catch (err) {
            this.catchError(this._translate.instant('products.works.createEdit.loadError'), err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async handleSaveClicked() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        try {
            this._spinner.show({ type: "spinner" });

            let code = '';

            if (this.editMode) {
                const work: ApiWorkData = {
                    description: this.form.get('description').value,
                    managed: Boolean(this.form.get('managed').value.id),
                    default: false
                };
                code = this.form.get('code').value;
                await this._workService.updateWork(this.workId, work);
            } else {
                const work: ApiWorkData = {
                    companyId: parseInt(localStorage.getItem('companyId')),
                    description: this.form.get('description').value,
                    code: this.form.get('code').value,
                    managed: Boolean(this.form.get('managed').value.id),
                    default: false
                }
                code = work.code;
                await this._workService.createWork(work);
            }

            this._translate.stream([
                'products.works.createEdit.saveTitle',
                'products.works.createEdit.createSuccess',
                'products.works.createEdit.editSuccess',
            ], { name: code }).subscribe(async (translations) => {
                const title = translations['products.works.createEdit.saveTitle'];
                let message = translations['products.works.createEdit.createSuccess'];
                if (this.editMode) {
                    message = translations['products.works.createEdit.editSuccess'];
                } 
                this.createToast('check', title, message);
            });

            this._router.navigate(['/works']);
        } catch (err) {
            if (err?.error.toString().includes("already exists")){
                this.catchError(this._translate.instant('products.works.createEdit.yetExits'), err);
            } else {
                if (this.editMode) {
                    this.catchError(this._translate.instant('products.works.createEdit.editError', {code: this.form.get('code').value}), err);
                } else {
                    this.catchError(this._translate.instant('products.works.createEdit.createError', {code: this.form.get('code').value}), err);
                }
            }
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async handleCancelClicked() {
        if (this.form.touched) {
            const code = this.editMode ? this.editWork.code : this.form.get('code').value;

            this._translate.stream([
                'products.works.createEdit.closeWithoutSavingNewTitle',
                'products.works.createEdit.closeWithoutSavingNewMessage',
                'products.works.createEdit.closeWithoutSavingNewMessageGeneric',
                'products.works.createEdit.closeWithoutSavingEditTitle',
                'products.works.createEdit.closeWithoutSavingEditMessage',
                'products.works.createEdit.confirmButton',
                'products.works.createEdit.cancelButton',
            ], { name: code }).subscribe(async (translations) => {
                const title = translations[this.editMode
                    ? 'products.works.createEdit.closeWithoutSavingEditTitle'
                    : 'products.works.createEdit.closeWithoutSavingNewTitle'];
                const message = translations[this.editMode
                    ? 'products.works.createEdit.closeWithoutSavingEditMessage'
                    : (code
                        ? 'products.works.createEdit.closeWithoutSavingNewMessage'
                        : 'products.works.createEdit.closeWithoutSavingNewMessageGeneric')];

                const newButtons = {
                    primary: {
                        text: this._translate.instant('products.works.createEdit.saveExitButton'),
                    },
                    secondary: {
                        text: this._translate.instant('products.works.createEdit.exitButton'),
                    },
                    tertiary: {
                        text: this._translate.instant('products.works.createEdit.cancelButton'),
                    },
                };
                const editButtons = {
                    primary: {
                        text: this._translate.instant('products.works.createEdit.confirmButton'),
                    },
                    tertiary: {
                        text: this._translate.instant('products.works.createEdit.cancelButton'),
                    },
                };
                const buttons = this.editMode ? editButtons : newButtons;

                this.createPopup(title, message, 'error', buttons, async (button: PopupResult) => {
                    if (button == 'primary') {
                        if (this.editMode)
                            await this._router.navigate(['/works']);
                        else
                            await this.handleSaveClicked();
                    } else if (button == 'secondary') {
                        if (!this.editMode)
                            await this._router.navigate(['/works']);
                    }
                });
            });
        } else {
            // Do not need to confirm because the form was not touched
            await this._router.navigate(['/works']);
        }
    }

    private catchError(message: string, error: Error) {
        console.error(error);

        const config: NotificationConfig = {
            content: message,
            type: 'toast',
            style: 'error',
            timeout: 5000,
            position: 'right',
        };
        this._notification.show(config);
    }

    private createPopup(title: string, message: string, level: NotifyLevel, buttons: any, func: (button: PopupResult) => void) {
        const popupConfig: PopupConfig = {
            title: title,
            level: level,
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: buttons,
        };
        const bodyConfig: PopupBodyConfig = {
            content: message,
        };
        this._popup.show(popupConfig, bodyConfig).subscribe(func);
    }

    private async createToast(style: NotificationStyle, title: string, message: string) {
        const config: NotificationConfig = {
            title: title,
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        };
        await this._notification.show(config);
    }

}
