import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UiService } from "../../../services/ui.service";
import { AuthService } from "../../../services/auth.service";
import { OverlaySpinnerService } from '@vapor/angular-ui';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-oauth-callback",
  template: `
    <div class="callback-container">
      <mat-spinner [diameter]="50"></mat-spinner>
      <p>{{ "login.tsid.completing_auth" | translate }}</p>
    </div>
  `,
  styles: [
    `
      .callback-container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        p {
          font-family: Montserrat, sans-serif;
          color: #666;
          margin: 0;
        }
      }
    `,
  ],
})
export class OAuthCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private uiService: UiService,
    private _spinner: OverlaySpinnerService,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    const code = this.route.snapshot.queryParamMap.get("code");
    const state = this.route.snapshot.queryParamMap.get("state");

    // Verify state to prevent CSRF attacks
    const storedState = sessionStorage.getItem('oauth_state');
    sessionStorage.removeItem('oauth_state');

    const code_verifier = sessionStorage.getItem('oauth_code_verifier')
    sessionStorage.removeItem('oauth_code_verifier');

    if (!code || !code_verifier || !state || state !== storedState) {
      this.translate.get("login.tsid.invalid_callback").subscribe((res) => {
        this.uiService.showNotification(res);
      });
      this.router.navigate(["/login"]);
      return;
    }

    try {
      this._spinner.show({ type: "spinner" });
      await this.authService.handleCallback(code, code_verifier);
      this.translate.get("login.success").subscribe((res) => {
        this.uiService.showNotification(res);
      });
      this._spinner.removeOverlay();
      this.router.navigate(["/stops"]);
    } catch (error) {
      console.error("Callback error:", error);
      this.translate.get("login.tsid.callback_error").subscribe((res) => {
        this.uiService.showNotification(res, 'error');
      });
      this._spinner.removeOverlay();
      this.router.navigate(["/login"]);
    }
  }
}
