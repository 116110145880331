import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { CompanyService } from './company.service';
import { TokenResponse, UserInstance, UserRole } from '../models/user.model';
import { CompanyInstance } from '../models/company.model';
import { Router } from '@angular/router';
import { ThemeService } from "./theme.service";
import { EnvConfigurationService } from './env-config.service';

interface LoginResponse {
    redirectUrl: string;
}

interface PKCEChallenge {
	codeVerifier: string;
	codeChallenge: string;
}

@Injectable()
export class AuthService {
    public user: UserInstance;
    public authenticated: boolean;
    public token: string;

    public oauthObject?: TokenResponse;
    private company: CompanyInstance;

    public onNavbarChange = new EventEmitter<void>();

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
        private _company: CompanyService,
        private _cookieService: CookieService,
        private router: Router,
        private _themeService: ThemeService,
        private _envSettings: EnvConfigurationService
    ) {
    }

    async clearToken() {
        this.clearLocalStorage();
        this._cookieService.deleteAll('/', this._config.getCookieDomain());
        await this.loadState();
    }

    async login(mail: String, password: String, language: String): Promise<any> {
        await this.clearToken();
        const url = this._config.API.url + '/login';

        const data = {
            email: mail,
            password: password
        };

        const response = await this._http.post<UserInstance>(url, data);
        this.user = response.body;

        if (this.user.role !== UserRole.admin && this.user.role !== UserRole.manager) {
            throw new Error('no_role');
        }

        this.token = this.user.token;

        this.saveStateToLocalStorage(language);
        await this.loadState();

        this.onNavbarChange.emit();
    }

    async logout() {
        const url = `${this._config.API.url}/logout`;
        try {

            if (this._cookieService.get("id_token")) {
                await this.oauthLogout();
            } else {
                const response = await this._http.put(url, {});
    
                if (response.status === 200) {
                    await this.clearToken();
                    this.router.navigateByUrl('/login');
                } else {
                    return;
                }
            }
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    private clearLocalStorage() {
        const prev_saved_lang = localStorage.getItem('lang');
        localStorage.clear();

        const lang = prev_saved_lang === "null" ? "en" : prev_saved_lang;
        localStorage.setItem('lang', lang);
    }

    private async saveStateToLocalStorage(language: String) {
        this.token && localStorage.setItem('token', this.token);
        this.user.id && localStorage.setItem('id', this.user.id.toString());
        this.user.email && localStorage.setItem('email', this.user.email);
        (this.user.role || this.user.role === 0) && localStorage.setItem('role', this.user.role.toString());
        if (this.user && this.user.companyId) {
            localStorage.setItem('companyId', this.user.companyId.toString());
        }
        if (this.user.lastName || this.user.firstName) {
            localStorage.setItem('lastName', this.user.lastName ? this.user.lastName : '');
            localStorage.setItem('firstName', this.user.firstName ? this.user.firstName : '');
        }

        localStorage.setItem('lang', language.toString())
    }

    public async loadState() {
        const token = localStorage.getItem('token');
        let access_token = this._cookieService.get('access_token');
        const refresh_token = this._cookieService.get('refresh_token');

        if (refresh_token && !access_token) {
            await this._http.checkTokenExpired();
            access_token = this._cookieService.get('access_token');
        }

        if (token) {
            this.user = {
                id: parseInt(localStorage.getItem('id'), 10),
                email: localStorage.getItem('email'),
                role: parseInt(localStorage.getItem('role'), 10),
                firstName: localStorage.getItem('firstName'),
                lastName: localStorage.getItem('lastName'),
                companyId: parseInt(localStorage.getItem('companyId'), 10)
            };

            // Force logout and page reload when role is missing
            if (localStorage.getItem('role') === null || Number.isNaN(this.user.role)) {
                this.clearLocalStorage();
                location.reload();
            }

            this.token = localStorage.getItem('token');
            this.authenticated = true;

            // Set the token
            this._http.setDefaultHeader('Authorization', `bearer ${this.token}`);
        } else if (access_token) { 
            this.user = {
                id: parseInt(localStorage.getItem('id'), 10),
                email: localStorage.getItem('email'),
                role: parseInt(localStorage.getItem('role'), 10),
                firstName: localStorage.getItem('firstName'),
                lastName: localStorage.getItem('lastName'),
                companyId: parseInt(localStorage.getItem('companyId'), 10),
                oauthObject: {
                    access_token: access_token,
                    id_token: this._cookieService.get('id_token'),
                    refresh_token: this._cookieService.get('refresh_token'),
                }
            };

            // Force logout and page reload when role is missing
            if (localStorage.getItem('role') === null || Number.isNaN(this.user.role)) {
                this.clearLocalStorage();
                this._cookieService.deleteAll('/', this._config.getCookieDomain());
                location.reload();
            }

            this.authenticated = true;
        } else {
            this.user = null;
            this.authenticated = false;
            this.token = null;
        }

        this.checkPlantAndOrderManager();
    }

    private async checkPlantAndOrderManager() {

        if (this.user && this.user.role !== UserRole.admin) {
            localStorage.setItem('companyId', this.user.companyId.toString());
            const company: CompanyInstance = await this._company.getCompany(this.user.companyId);
            if (company.Plants && company.Plants.length) {
                this.user.Plants = company.Plants;
                localStorage.setItem('plantId', this.user.Plants[0].id.toString());
            }
            if (company.satelliteUrl) {
                if (await this.verifyInsertOrder(company.satelliteUrl)) {
                    localStorage.setItem('satelliteUrl', company.satelliteUrl);
                }
            }
        }
    }

    async verifyInsertOrder(uri: string): Promise<boolean> {
        const url = uri + '/plugins';

        try {
            const res = await this._http.get(url);
            return res.body[1] === 'insertOrder' ? true : false;

        } catch (err) {
            return false;
        }
    }

    
    async oauthLogin(): Promise<void> {
        const loginUrl = `${this._envSettings.settings.api.urlV2}/auth/login`;
        try {
            const PKCEChallenge = await this.generatePKCEChallenge();
            const state = this.generateState();
            sessionStorage.setItem('oauth_state', state);
            sessionStorage.setItem('oauth_code_verifier', PKCEChallenge.codeVerifier);
            const response = await this._http.post<LoginResponse>(loginUrl, { state, code_challenge: PKCEChallenge.codeChallenge });
            if (response && response.body) {
                if (response.body.redirectUrl) {
                    window.location.href = response.body.redirectUrl;
                }
            }
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    }

    async oauthLogout(): Promise<void> {
        const logoutUrl = `${this._envSettings.settings.api.urlV2}/auth/logout`;
        try {
            const response = await this._http.post<LoginResponse>(logoutUrl, { id_token: this._cookieService.get("id_token") });
            if (response && response.body && response.body.redirectUrl) {
                this._cookieService.deleteAll('/', this._config.getCookieDomain());
                window.location.href = response.body.redirectUrl;
            }
        } catch (error) {
            console.error("Logout error:", error);
            throw error;
        }
    }
    
    async handleCallback(code: string, code_verifier: string): Promise<any> {
        const callbackUrl = `${this._envSettings.settings.api.urlV2}/auth/callback`;
        const response = await this._http.post<UserInstance>(callbackUrl, { code, code_verifier })
        this.user = response.body;

        if (this.user.role !== UserRole.admin && this.user.role !== UserRole.manager) {
            throw new Error('no_role');
        }


        this.oauthObject = this.user.oauthObject;

        if (this.oauthObject) {
            this._cookieService.set('access_token', this.oauthObject.access_token, this.oauthObject.expires_in / (24 * 60 * 60), '/', this._config.getCookieDomain());
            this._cookieService.set('id_token', this.oauthObject.id_token, this.oauthObject.expires_in / (24 * 60 * 60), '/', this._config.getCookieDomain());
            if (this.oauthObject.refresh_token) {
                this._cookieService.set('refresh_token', this.oauthObject.refresh_token, 30, '/', this._config.getCookieDomain());
            }
        }

        this.saveStateToLocalStorage(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
        await this.loadState();

        this.onNavbarChange.emit();
    }

    // Base64URL encoding function (RFC 7636 compliant)
	base64UrlEncode = (buffer: ArrayBuffer): string =>
		btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
	
	// Generate cryptographically secure random bytes
	generateSecureRandomBytes = (length: number = 32): Uint8Array => {
		const buffer = new Uint8Array(length);
		return window.crypto.getRandomValues(buffer);
	};
	
	// Generate state parameter for CSRF protection
	generateState = (): string => {
		// Generate 32 bytes (256 bits) of random data
		const stateBuffer = this.generateSecureRandomBytes(32);
		return this.base64UrlEncode(stateBuffer);
	};
	
	// SHA-256 hash function that returns a Promise with the hashed result
	sha256Hash = (input: string): Promise<ArrayBuffer> =>
		crypto.subtle.digest(
			'SHA-256',
			new TextEncoder().encode(input)
		);
	
	// Generate PKCE code verifier (high entropy random string)
	generateCodeVerifier = (): string => {
		// Use 64 bytes (512 bits) for military-grade security
		// RFC 7636 requires between 43-128 chars
		const randomBytes = this.generateSecureRandomBytes(64);
		return this.base64UrlEncode(randomBytes);
	};
	
	// Generate code challenge from verifier using SHA-256
	generateCodeChallenge = (codeVerifier: string): Promise<string> =>
		this.sha256Hash(codeVerifier)
		.then(buffer => this.base64UrlEncode(buffer));
	
	// Complete PKCE challenge generation using composition
	generatePKCEChallenge = (): Promise<PKCEChallenge> => {
		const codeVerifier = this.generateCodeVerifier();
		return this.generateCodeChallenge(codeVerifier)
		.then(codeChallenge => ({ codeVerifier, codeChallenge }));
	};
}
