import { OnInit, Component, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyInstance } from '../../../models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlsService } from '../../../services/controls.service';
import { ControlIstance } from '../../../models/control.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductInstance } from '../../../models/product.model';
import { TaskIstance } from '../../../models/task.model';

import { TaskService } from '../../../services/task.service';
import { ControlSchedulingIstance } from '../../../models/control-scheduling.model';
import { ErrorService } from '../../../services/error.service';
import { ErrorInstance, ErrorTypeString, ErrorType } from '../../../models/error.model';
import { ControlSchedulingService } from '../../../services/controlScheduling.service';
import { DeviceInstance } from '../../../models/device.model';
import { Utility } from '../../../utils/utility';
import { PageWithLoader } from "../page-with-loader";
import { ThemeService } from "../../../services/theme.service";
import { Drawer, FontService, OverlaySpinnerService } from '@vapor/angular-ui';
import { TsTreeListColumn, TsTreeListRowDraggingOnDragChange, TsTreeListRowDraggingOnReorder } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faArrowLeft, faPencil, faTrashAlt, faCopy, faCheck, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ControlTaskDrawerComponent } from '../../ui/control-task-drawer/control-task-drawer.component';
import { TreeListComponent } from '@vapor/angular-ui-extra';


interface SelectEntry {
    id: number;
    val: string;
}

export interface ErrorModelForSelect extends ErrorInstance {
    selectLabel: string;
    errorTypeString: ErrorTypeString;
}

export interface WorkPorcessPhaseComputed {
    value: string,
    phaseId: number
}

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ControlComponent extends PageWithLoader implements OnInit, OnDestroy {
    isAdmin: boolean;
    id: number;
    private sub: any;
    selectedCompanyId: number;
    selectedPlantId: number;
    control: ControlIstance = {};
    products: ProductInstance[];
    selectedProducts: ProductInstance[];
    filteredProducts: ProductInstance[] = [];
    devices: DeviceInstance[];
    devicesFullList: DeviceInstance[];
    selectedDevices: DeviceInstance[];
    filteredDevices: DeviceInstance[] = [];
    selectedError: ErrorInstance;
    errors: ErrorInstance[];
    errorsFullList: ErrorInstance[];
    errorGroupsList = [];
    tmpErrors: SelectEntry[] = [];
    selectSchedulings = [];

    tasks: TaskIstance[];
    tmpTasks: TaskIstance[];
    selectedTask: TaskIstance;
    taskColumns: TsTreeListColumn[] = [];
    controlSchedulings: ControlSchedulingIstance[];
    selectedCompany: CompanyInstance;

    deviceOptions: SelectEntry[] = [];
    productOptions: SelectEntry[] = [];

    selectedDeviceOptions: SelectEntry[] = [];
    selectedProductOptions: SelectEntry[] = [];

    form: FormGroup;

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    @ViewChild('tasksTable', { static: false }) tasksTable: TreeListComponent;
    @ViewChild('newTaskTemplate', { static: true }) newTaskTemplate: TemplateRef<any>;
    @ViewChild('taskEditTemplate', { static: true }) taskEditTemplate: TemplateRef<any>;
    @ViewChild('taskDuplicateTemplate', { static: true }) taskDuplicateTemplate: TemplateRef<any>;
    @ViewChild('taskDrawerBottomTemplate', { static: true }) taskDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('taskDrawer', { static: false }) taskDrawer: ControlTaskDrawerComponent;

    constructor(
        private _device: DeviceService,
        private _ui: UiService,
        private _products: ProductService,
        private _controls: ControlsService,
        private _navbar: NavbarService,
        private readonly _sidebar: SidebarService,
        private _scheduling: ControlSchedulingService,
        private _erros: ErrorService,
        private _tasks: TaskService,
        private _drawer: Drawer,
        private _translate: TranslateService,
        private _font: FontService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _utility: Utility,
        private _fb: FormBuilder,
        private _spinner: OverlaySpinnerService,
        public _auth: AuthService,
        public cdRef: ChangeDetectorRef,
        _themeService: ThemeService
    ) {
        super(_themeService);
        this._font.addIcon(faArrowLeft, faPencil, faTrashAlt, faCopy, faCheck, faCheckCircle);

        this.form = this._fb.group({
            description: [null, [Validators.required]],
            idCodeEnabled: [false],
            schedulings: this._fb.array([this.createSchedulingGroup()]),
            devices: [null],
            products: [null]
        })
    }

    private createSchedulingGroup(): FormGroup {
        return this._fb.group({
            id: [null],
            option: this._fb.group({
                optionId: [null],
                description: [null],
            }),
            quantity: [null],
            errorId: [null],
            type: [null]
        });
    }

    // Form Utils

    get formValid(): boolean {
        return this.form.valid;
    }

    get _schedulings(): FormArray {
        return this.form.get('schedulings') as FormArray
    }

    addEmptyScheduling() {
        const schedulingsArray = this._schedulings;
        schedulingsArray.push(this.createSchedulingGroup());
    }

    handleCodeEnabledChange(event: boolean) {
        this.form.patchValue({
            idCodeEnabled: event
        });
    }

    async ngOnInit(): Promise<void> {

        await this._auth.loadState();
        this._spinner.show({ type: "spinner" });

        this._translate.stream([
            'control.title',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['control.title']);
        });

        this.sub = this._route.params.subscribe(params => {
            this.id = +params['id']; // (+) converts string 'id' to a
        });
        // get control
        this.control = await this._controls.get(this.id);

        this.selectSchedulings = [
            { id: 1, val: this._translate.instant('control.selectScheduling.start-device'), type: 2, quantity: 0, cycle: false },
            { id: 2, val: this._translate.instant('control.selectScheduling.after-pieces'), type: 1, quantity: 1, cycle: false },
            { id: 3, val: this._translate.instant('control.selectScheduling.every-pieces'), type: 1, quantity: 1, cycle: true },
            { id: 4, val: this._translate.instant('control.selectScheduling.after-minutes'), type: 2, quantity: 1, cycle: false },
            { id: 5, val: this._translate.instant('control.selectScheduling.every-minutes'), type: 2, quantity: 1, cycle: true },
            { id: 6, val: this._translate.instant('control.selectScheduling.after-stop'), type: 3, quantity: 1, errorId: null, cycle: false, repeat: 1 }
        ]


        // get logged company
        this.selectedCompanyId = this.control.companyId;

        // get logged plant
        this.selectedPlantId = this.control.plantId;

        // initializate errors
        await this._ngInitDevices();
        await this._ngInitErrors();
        await this._ngInitProducts();

        // Update form
        if (this.control) {
            await this._initializeControlScheduling();

            this.updateForm(this.control);
            // fill task array
            if (this.control.Tasks) {
                this.tasks = [...this.control.Tasks];
                this.updateTaskTable();
            }
        } else {
            this.tasks = [];
        }

        this._spinner.removeOverlay();
    }

    private updateTaskTable() {

        this._translate.stream(['control.columns.question', 'control.columns.enabled']).subscribe((translations) => {

            const defaultColumn: TsTreeListColumn = {
                dataField: '',
                headerPlaceholder: '',
                allowFiltering: false,
                allowEditing: false,
                allowSorting: false,
                width: '100%'
            };

            this.taskColumns = [
                {
                    ...defaultColumn,
                    caption: 'Sort',
                    width: 100,
                    calculateDisplayValue: (row: TaskIstance) => row.ControlsTasks[0].order
                },
                {
                    ...defaultColumn,
                    dataField: 'description',
                    dataType: 'string',
                    caption: translations['control.columns.question'],
                },
                {
                    ...defaultColumn,
                    dataField: 'closedQuestion',
                    dataType: 'string',
                    caption: 'Closed question',
                    calculateDisplayValue: (row: TaskIstance) => row.closedQuestion ? 'Yes' : 'No'
                },
                {
                    ...defaultColumn,
                    dataField: 'TasksParameters',
                    dataType: 'string',
                    caption: 'Values to Insert',
                    calculateDisplayValue: (row: TaskIstance) => {
                        return row?.TasksParameters?.length > 0 ? row.TasksParameters.length : "";
                    }
                },
                {
                    ...defaultColumn,
                    dataType: 'boolean',
                    caption: translations['control.columns.enabled'],
                    cellTemplate: "tplEnabled",
                    width: 150
                },
                { // actions
                    ...defaultColumn,
                    caption: '',
                    alignment: 'center',
                    width: 135,
                    allowFiltering: false,
                    allowResizing: false,
                    allowFixing: false,
                    cellTemplate: 'tplActions',
                },
            ]

        })
    }

    async _ngInitProducts() {
        // get products
        const deviceAttributes = ['id'];
        this.products = await this._products.getProducts(null, this.selectedPlantId, true, null, deviceAttributes);

        // Fill Product Options list
        this.productOptions = this.products.map(product => this.mapProductsToUI(product));
        this.productOptions = [{
            id: -1,
            val: this._translate.instant('control.all-products'),
        }, ...this.productOptions]

        // Fill product options

        this._buildProducts(true);

    }

    async _ngInitDevices() {
        // get device
        this.devicesFullList = await this._device.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

        // Fill Device Options list
        this.deviceOptions = this.devicesFullList.map(device => this.mapDeviceToUI(device));
        this.deviceOptions = [{
            id: -1,
            val: this._translate.instant('control.all-devices'),
        }, ...this.deviceOptions];

        await this._buildDevices(true);
    }

    private _buildProducts(fromInit: boolean = false) {
        const allProductsOption = this.productOptions.find(p => p.id === -1);

        if (!fromInit) {
            this.selectedProductOptions = this.control.Products.length ? this.control.Products.map(p => this.mapProductsToUI(p)) : this.control.allProducts
                ? [allProductsOption] : [];
        }
    }

    private async _buildDevices(fromInit: boolean = false) {
        const allDevicesOption = this.deviceOptions.find((d) => d.id === -1);

        if (!fromInit) {
            this.selectedDeviceOptions = this.control.Devices.length ? this.control.Devices.map(device => this.mapDeviceToUI(device)) : this.control.allDevices
                ? [allDevicesOption] : [];
        }

        // Filter devices
        if (this.selectedError && this.selectedError.Devices && this.selectedError.Devices.length) {
            const selectedErrorDevicesIds = this.selectedError.Devices.map(d => d.id);

            if (selectedErrorDevicesIds.length <= 0) {
                // Reset devices list and put empty list in selectable devices
                this.selectedDeviceOptions = [];
                this.deviceOptions = [allDevicesOption];
            } else {
                this.selectedDeviceOptions = this.deviceOptions.filter((device) => selectedErrorDevicesIds.includes(device.id));
            }
        }

        if (this.selectedDeviceOptions.length > 0) {
            await this._buildErrors();
        }
    }

    // Device Controller

    async clearAllDevices() {
        this.selectedDeviceOptions = [];
        await this._buildErrors();
    }

    // Task Controller

    showTaskDrawer(type: 'duplicate' | 'edit' | 'create', task?: TaskIstance) {
        if (this._drawer.isOpened()) {
            console.log("closing drawer")
            this._drawer.close();
        }

        if (task && type === 'edit') {
            this.selectedTask = task;
        } else if (task && type === 'duplicate') {
            this.selectedTask = {
                ...task,
                description: task.description + ` (${this._translate.instant('control.dialog.duplicate')})`,
            }
        }

        this._drawer.open({
            title: type === 'duplicate' ? this._translate.instant('control.dialog.duplicate') : type === 'edit' ? this._translate.instant('control.dialog.title-edit') : this._translate.instant('control.dialog.title'),
            subTitle: this.selectedTask ? this.selectedTask.description : "",
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: type === 'create' ? this.newTaskTemplate : type === 'duplicate' ? this.taskDuplicateTemplate : this.taskEditTemplate,
            bottomBarTemplate: this.taskDrawerBottomTemplate
        }).subscribe(() => {
            this._drawer.expand();
            console.log("new drawe", this._drawer)
        });
    }

    async deleteTask(taskId: number) {
        if (taskId) {
            const res = await this._tasks.delete(taskId, this.control.id);
            if (res.status === 200) {
                this.tasks = this.tasks.filter(t => t.id !== taskId);
                this._refreshTasks();
                this._ui.showNotification(this._translate.instant('control.deleted-successfully'));
            } else {
                this._ui.showNotification(this._translate.instant('control.deleted-error'), 'error');
            }
        }
    }

    async enablingTask(taskId: number, value: any) {
        this.tasks.forEach(t => {
            if (t.id === taskId) t.ControlsTasks[0].enabled = value;
        });

    }

    // Schedules Controller
    async deleteSchedulingIfExists(index: number) {
        const schedulingsArray = this._schedulings;

        const currentScheduling = schedulingsArray.at(index).value;

        if (currentScheduling.id !== null) {
            const res = await this._scheduling.delete(currentScheduling.id, this.control.id);
            if (res.status === 200) {
                this.controlSchedulings = this.controlSchedulings.filter(c => c.id !== currentScheduling.id);
                this._ui.showNotification(this._translate.instant('control.deleted-scheduling-success'));
                schedulingsArray.removeAt(index);
            } else {
                this._ui.showNotification(this._translate.instant('control.deleted-scheduling-error'), 'error');
            }
        } else {
            schedulingsArray.removeAt(index);
        }

        if (schedulingsArray.length === 0) this.addEmptyScheduling();
        this.clearAllDevices();

    }


    isQuantityRequired(optionId: number) {
        if (!optionId) return false;
        else if (optionId === 1) return false;
        else if (optionId === 7) return false;
        else return true;
    }

    isRepeatRequired(scheduling: ControlSchedulingIstance) {

        if (!scheduling.optionId) return false;
        else if (scheduling.optionId === 6) return false;
        else return true;
    }

    async isStopRequired(scheduling: ControlSchedulingIstance) {

        if (!this.errors) {

        }

        if (!scheduling.optionId) return false;
        else if (scheduling.optionId === 6) {
            if (scheduling.errorId) this.selectedError = this.errors.find(e => e.id === scheduling.errorId);
            return true;
        } else return false;
    }

    private async _initializeControlScheduling() {
        // fill scheduling array
        if (this.control.ControlSchedulings) {
            this.controlSchedulings = [...this.control.ControlSchedulings];

            for (const schedule of this.controlSchedulings) {
                if (schedule.type === 3) schedule.optionId = 6;
                else if (schedule.type === 2 && schedule.cycle) schedule.optionId = 5;
                else if (schedule.type === 2 && !schedule.cycle && schedule.quantity > 0) schedule.optionId = 4;
                else if (schedule.type === 1 && schedule.cycle) schedule.optionId = 3;
                else if (schedule.type === 1 && !schedule.cycle) schedule.optionId = 2;
                else if (schedule.type === 4) schedule.optionId = 7;
                else schedule.optionId = 1;
            }
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    async onDevicesChange(event: SelectEntry[]) {

        if (!event) return;

        const allDevicesIndex = event.findIndex(e => e.id === -1);

        if (allDevicesIndex !== -1) {
            if (allDevicesIndex === event.length - 1) {
                // Remove all other options if "select all" is the last option selected
                event = [event[allDevicesIndex]];

                this.selectedError = null;
                this._schedulings.controls.forEach((control) => {
                    control.get('errorId').setValue(null);
                });
            } else if (allDevicesIndex === 0) {
                // Remove "select all" if it is the first option and there are other options
                event = event.filter(e => e.id !== -1);
            }
        }

        this.selectedDeviceOptions = this.deviceOptions.filter(e => event.map(e => e.id).includes(e.id));

        await this._buildErrors();
    }

    onProductsChange(event: SelectEntry[]) {
        if (!event) return;

        const allProductsIndex = event.findIndex(e => e.id === -1);

        if (allProductsIndex !== -1) {
            if (allProductsIndex === event.length - 1) {
                // Remove all other options if "select all" is the last option selected
                event = [event[allProductsIndex]];
            } else if (allProductsIndex === 0) {
                // Remove "select all" if it is the first option and there are other options
                event = event.filter(e => e.id !== -1);
            }
        }

        this.selectedProductOptions = this.productOptions.filter(e => event.map(e => e.id).includes(e.id));
    }

    private _refreshTasks(): void {
        this.tasks = [...this.tasks];
    }

    private async _ngInitErrors() {
        this.errorsFullList = await this._erros.getErrorsByCompany(this.selectedCompanyId, { populateErrorTag: true, populateDevices: true });
        // this.errorsFullList = this.errorsFullList.filter((e) => e.companyId === this.selectedCompanyId);
        await this._buildErrors();
    }

    private async _buildErrors() {
        this.errors = this._utility.cloneArray(this.errorsFullList);
        /**
         * Se sono selezionati 1 o più devices dalla rispettiva select,
         * bisogna filtrare il sottoinsieme di errori comuni a tutti i
         * device per evitare inconsistenze e che il controllo non sia
         * eseguito.
         */
        if (this.selectedDeviceOptions && this.selectedDeviceOptions.length > 0) {
            const selectedDevicesIds = this.selectedDeviceOptions.map(e => e.id);

            this.errors = this.errors.filter(err => {
                const errorDevIds = err.Devices.map(dd => dd.id);
                const accepted = selectedDevicesIds.filter(sdi => !errorDevIds.includes(sdi)).length <= 0;
                return accepted;
            });
        }
        await this.buildErrorsGroups();
    }

    private async buildErrorsGroups() {
        this.errorGroupsList = [];
        const unplannedPrefix = 'Unplanned';
        const plannedPrefix = 'Planned';
        const errorsList = this.errors;
        const errorsIds = new Set(errorsList.map(e => e.id));
        let errorsTagList = await this._erros.getTagsWithErrors(this.selectedCompanyId);

        errorsTagList = errorsTagList.filter((errorTag) => {
            return errorTag.Errors.some(e => errorsIds.has(e.id));
        });

        for (const tag of errorsTagList) {
            const planned = {
                id: tag.id,
                name: tag.name ? `${plannedPrefix} - ${tag.name}` : `${plannedPrefix} - Other`,
                Errors: []
            };
            const unplanned = {
                id: tag.id,
                name: tag.name ? `${unplannedPrefix} - ${tag.name}` : `${unplannedPrefix} - Other`,
                Errors: []
            };

            tag.Errors.sort(this.sortByText);

            for (const error of tag.Errors) {
                if (errorsIds.has(error.id)) {
                    if (error.type === 0) {
                        unplanned.Errors.push(error);
                    }
                    if (error.type === 1) {
                        planned.Errors.push(error);
                    }
                }
            }

            if (planned.Errors.length > 0) {
                planned.Errors = this._transformErrorsToErrorsForSelect(planned.Errors);
                this.errorGroupsList.push(planned);
            }
            if (unplanned.Errors.length > 0) {
                unplanned.Errors = this._transformErrorsToErrorsForSelect(unplanned.Errors);
                this.errorGroupsList.push(unplanned);
            }
        }

        this.errorGroupsList.sort(this.sortByName);

        this.tmpErrors = this.errorGroupsList.reduce((acc, tag) => {
            const errors = tag.Errors.map((error: ErrorInstance) => ({
                id: error.id,
                val: `${tag.name} - ${error.text}`
            }));
            return acc.concat(errors);
        }, []);

    }

    private sortByName(a, b) {
        if (a && b && a.name && b.name && a.name.toUpperCase() >= b.name.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }
    private sortByText(a, b) {
        if (a && b && a.text && b.text && a.text.toUpperCase() >= b.text.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }

    private _transformErrorsToErrorsForSelect(errors: ErrorInstance[]) {
        return errors.map((error: ErrorModelForSelect) => {
            const errorTypeString = (error.Category && error.Category.type === ErrorType.planned) ? 'Planned' : 'Unplanned';
            error.selectLabel = error.text;
            error.errorTypeString = errorTypeString;
            return error;
        });
    }

    private updateForm(control: ControlIstance) {

        const hasDevice = (id: number) => {
            return control?.Devices?.find((device) => device.id === id) !== undefined;
        };

        const hasProducts = (id: number) => {
            return control?.Products?.find((product) => product.id === id) !== undefined;
        }

        const _devices = control.allDevices ? [this.deviceOptions.find(d => d.id === -1)] : this.deviceOptions.filter((entry: SelectEntry) => hasDevice(entry.id));
        const _products = control.allProducts ? [this.productOptions.find(p => p.id === -1)] : this.productOptions.filter((entry) => hasProducts(entry.id));

        this.form.patchValue({
            description: control.description,
            idCodeEnabled: control.idCodeEnabled,
            devices: _devices,
            products: _products
        });

        const schedulingsArray = this.form.get('schedulings') as FormArray;
        schedulingsArray.clear();

        this.controlSchedulings?.forEach(scheduling => {
            schedulingsArray.push(this._fb.group({
                id: scheduling.id,
                option: {
                    optionId: scheduling.optionId,
                    description: this.selectSchedulings.find(s => s.id === scheduling.optionId).val
                },
                quantity: scheduling.quantity,
                errorId: scheduling.errorId,
                type: scheduling.type
            }));
        });

    }

    getQuantityLabel(optionId: number) {
        if (optionId === 2 || optionId === 3) {
            return this._translate.instant('control.scheduling-number-pieces')
        } else if (optionId === 5 || optionId === 4) {
            return this._translate.instant('control.scheduling-number-minutes')
        } else if (optionId === 6) {
            return this._translate.instant('control.scheduling-number-quantity')
        }
    }

    async onTaskFormSubmit() {
        const res = await this.taskDrawer.onSubmit();
        if (res.saved) {
            this._drawer.close();
            this._ui.showNotification(this._translate.instant('control.dialog.saved-successfully'));
        } else {
            this._ui.showNotification(this._translate.instant('control.dialog.saved-error'), 'error');
        }
    }

    async onFormSubmit() {
        // Control details form

        this._spinner.show({ type: "spinner" });
        let error = false;

        const schedules = this._schedulings.value.filter((s) => s.option.optionId).map((s) => ({
            id: s.id,
            optionId: s.option.optionId,
            quantity: s.quantity,
            errorId: typeof s.errorId === 'number' ? s.errorId : s.errorId?.id,
            type: s.type,
            cycle: this.selectSchedulings.find(sch => sch.id === s.option.optionId).cycle,
            repeat: 1
        }))


        if (schedules.length > 0) {
            schedules.forEach(s => {
                if (s.type === 3 && !s.errorId) error = true;
            });

            schedules.forEach(s => {
                delete s.optionId;
                if (!s.id) delete s.id;
                if (!s.errorId) delete s.errorId;
            });

            const schedulingData = {
                schedules,
                controlId: this.control.id,
                companyId: this.control.companyId,
                plantId: this.selectedPlantId,
            };

            if (error) {
                this._ui.showNotification(this._translate.instant('control.saved-error'), 'error');
                this._spinner.removeOverlay();

                return;
            }
            await this._scheduling.update(schedulingData);
        }

        // Using selectedDeviceOptions & selectedProductOptions to determine products and devices instead of form device/product values

        const allProducts = this.selectedProductOptions.some(p => p.id === -1);
        const allDevices = this.selectedDeviceOptions.some(d => d.id === -1);

        const productIds = allProducts ? [] : this.selectedProductOptions.map(p => p.id);
        const deviceIds = allDevices ? [] : this.selectedDeviceOptions.map(d => d.id);

        const tasks = {};

        for (const task of this.tasks) {
            tasks[task.id] = {
                enabled: task.ControlsTasks[0].enabled,
                order: task.ControlsTasks[0].order
            };
        }

        const data = {
            description: this.form.value.description,
            enabled: true,
            productIds,
            deviceIds,
            allProducts,
            allDevices,
            tasks,
            idCodeEnabled: this.form.value.idCodeEnabled
        };

        try {
            const res = await this._controls.update(this.control.id, data);
            this.control = res;
            await this._initializeControlScheduling();
            this._ui.showNotification(this._translate.instant('control.saved-successfully'));
        } catch (error) {
            this._ui.showNotification(this._translate.instant('control.saved-error'), 'error');
        } finally {
            this._router.navigate(['/quality-controls']);
        }

        this._spinner.removeOverlay();
    }

    onSelectedTaskEmit(data: any): void {
        this.tasks = data;
        this._refreshTasks();
    }

    // Tree List functions

    onDragChange: TsTreeListRowDraggingOnDragChange<TaskIstance> = event => {
        
        const sourceNode = this.tasksTable.getNodeByKey(event.rowData.id);
        let targetNode = this.tasksTable.getVisibleRowByIndex(event.toIndex)?.node;

        // prevent actions if order is not changed
        while (targetNode?.data) {
            if (targetNode.data.ID === sourceNode?.data.ID) {
                event.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    };

    onReorder: TsTreeListRowDraggingOnReorder<TaskIstance> = async event => {
        const sourceData = event.rowData;
        const toIndex = event.fromIndex > event.toIndex ? event.toIndex - 1 : event.toIndex;
        const targetData = toIndex >= 0 ? this.tasksTable.getVisibleRowByIndex(toIndex)?.node.data : null;

        const sourceIndex = this.tasks.indexOf(sourceData);
        this.tasks.splice(sourceIndex, 1);

        const targetIndex = this.tasks.indexOf(targetData) + 1;
        this.tasks.splice(targetIndex, 0, sourceData);

        for (const task of this.tasks) {
            task.ControlsTasks[0].order = this.tasks.indexOf(task) + 1;
        }
        this.tasksTable.treeListComponent.instance.refresh();
    }

    schedulingOptionChange(event: any, index: any) {
        if (!event) return;

        const schedulingsArray = this.form.get('schedulings') as FormArray;
        const schedulingGroup = schedulingsArray.at(index) as FormGroup;
        schedulingGroup.patchValue({
            option: {
                optionId: event.id,
                description: this.selectSchedulings.find(s => s.id === event.id)?.val || ''
            },
            quantity: event.quantity,
            type: event.type,
            errorId: null
        });
    }

    async selectStopReason(event: any, index: any) {
        if (event) {
            const selectedStopReason = event.id;
            this.selectedError = this.errors.find(err => err.id === selectedStopReason) || null;
            await this._buildDevices();
        }
    }

    displayErrorAccordingToID(error: number | SelectEntry) {
        if (!error) return;
        const _id = typeof error === 'number' ? error : error.id;

        return this.tmpErrors.find(err => err.id === _id)?.val || '';
    }

    onCancelDrawerClicked() {
        this._drawer.collapse();
    }

    handleCancelClicked() {
        this._router.navigate(['/quality-controls']);
    }

    private mapDeviceToUI(device?: DeviceInstance): SelectEntry {
        if (device) {
            return {
                id: device.id,
                val: device.label,
            };
        } else {
            return null;
        }
    }

    private mapProductsToUI(product?: ProductInstance): SelectEntry {
        if (product) {
            return {
                id: product.id,
                val: `${product.code} - ${product.name}`,
            }
        } else {
            return null
        }
    }
}
