import { PlantInstance } from './plant.model';

export enum UserRole {
    admin = 0,
    supervisor = 1,
    tablet = 2,
    manager = 3
}

export interface UserInstance {
    id?: number;

    token?: string;
    email?: string;
    password?: string;
    enabled?: boolean;

    role?: UserRole;
    plant?: PlantInstance;
    firstName?: string;
    lastName?: string;

    activationCode?: string;
    activated?: boolean;

    createdAt?: Date;
    updatedAt?: Date;

    tabletDeviceId?: number;
    MAC?: string;
    tabletSerial?: string;

    companyId?: number;
    Plants?: PlantInstance[];
    oauthObject?: TokenResponse;
}

export interface TokenResponse {
    access_token: string;
    id_token: string;
    refresh_token: string;
    token_type?: string;
    expires_in?: number;
}
