import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UiService } from './ui.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import jwtDecode from 'jwt-decode';
import { TokenResponse } from '../models/user.model';
import { ConfigService } from './config.service';

@Injectable()
export class HttpService {
    private defaultHeaders: HttpHeaders = new HttpHeaders();
    private _refreshPromise: Promise<void> | null = null;

    constructor(
        private _cookieService: CookieService,
        private _config: ConfigService,
        private http: HttpClient,
        private router: Router,
        private _ui: UiService
    ) {}

    setDefaultHeader(key: string, value: string): void {
        this.defaultHeaders = this.defaultHeaders.set(key, value);
    }

    async post<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        await this.checkTokenExpired();
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.post<T>(url, body, options)).toPromise();
    }

    async get<T>(url: string, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        await this.checkTokenExpired();
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.get<T>(url, options)).toPromise();
    }

    async delete<T>(url: string, body?: any,headers?: HttpHeaders): Promise<HttpResponse<T>> {
        await this.checkTokenExpired();
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), body , observe: 'response' as 'response' };
        return this.intercept<T>(this.http.delete<T>(url, options)).toPromise();
    }

    async put<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        await this.checkTokenExpired();
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.put<T>(url, body, options)).toPromise();
    }

    async patch<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        await this.checkTokenExpired();
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.patch<T>(url, body, options)).toPromise();
    }

    private mergeHeaders(headers: HttpHeaders): HttpHeaders {
        let mergedHeaders = new HttpHeaders(this.defaultHeaders.keys().reduce((acc, key) => {
            acc[key] = this.defaultHeaders.get(key) as string;
            return acc;
        }, {} as { [name: string]: string | string[] }));

        headers?.keys().forEach(key => {
            mergedHeaders = mergedHeaders.set(key, headers.get(key) as string);
        });

        return mergedHeaders;
    }

    private intercept<T>(observable: Observable<HttpResponse<T>>): Observable<HttpResponse<T>> {
        return observable.pipe(catchError((err: HttpErrorResponse) => {
            const urlParts = err && err.url ? err.url.split('/'): [];
            const urlAction = urlParts && urlParts.length > 0? urlParts[urlParts.length - 1].split('?')[0]: '';

            if (err.status === 401 && urlAction !== 'login' && urlAction !== 'plugins') {
                localStorage.clear();
                this._cookieService.deleteAll('/', this._config.getCookieDomain());
                this.router.navigate(['/login']);
                return EMPTY;
            } else if (err.status === 404) {
                this._ui.showNotification('Error 404: missing API endpoint', 'alert');
                return throwError(() => err);
            } else {
                // Rewrite the error object because err.error and err.message are read-only
                let errorObject: any;

                if (err.error && typeof err.error === 'string') {
                    try {
                        errorObject = JSON.parse(err.error);
                    } catch (ex) {
                        errorObject = JSON.parse(JSON.stringify(err.error));
                    }
                } else {
                    errorObject = err.error;
                }

                const modifiedError = {
                    ...err,
                    error: errorObject
                };
                return throwError(() => modifiedError);
            }
        }));
    }

    public async checkTokenExpired() {
        if (this._refreshPromise) {
            // If the refresh is already in progress, wait for the same promise to avoid two competing calls both trying to refresh the token at the same time
            return await this._refreshPromise;
        }

        const access_token = this._cookieService.get('access_token');
        const refresh_token = this._cookieService.get('refresh_token');
        if (!refresh_token) return;
        const decodedToken: any = access_token && jwtDecode(access_token);
        const exp = decodedToken?.exp;
        const now = new Date().getTime() / 1000; //seconds
        if (exp && (now - exp) < 60 * 5) return; //span > 5 min ok

        this._refreshPromise = new Promise(async (resolve, reject) => {
            try {
                const url = `${this._config.API.urlV2}/auth/refresh`;
                const body = { refresh_token };
                const options = { withCredentials: true, observe: 'response' as 'response' };
                const response = await this.http.post<TokenResponse>(url, body, options).toPromise();
                this._cookieService.deleteAll('/', this._config.getCookieDomain());
                const oauthObject = response.body;
                if (oauthObject) {
                    this._cookieService.set('access_token', oauthObject.access_token, oauthObject.expires_in / (24 * 60 * 60), '/', this._config.getCookieDomain());
                    this._cookieService.set('id_token', oauthObject.id_token, oauthObject.expires_in / (24 * 60 * 60), '/', this._config.getCookieDomain());
                    if (oauthObject.refresh_token) {
                        this._cookieService.set('refresh_token', oauthObject.refresh_token, 30, '/', this._config.getCookieDomain());
                    }
                }
                resolve();
            } catch (error) {
                console.error("Errore nel refresh del token:", error);
                this._cookieService.deleteAll('/', this._config.getCookieDomain());
                this.router.navigate(['/login']);
                reject(error);
            } finally {
                this._refreshPromise = null;
            }
        });
        return await this._refreshPromise;
    }
}
