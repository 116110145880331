<ts-tab-panel [panelTabs]="tabs" type="secondary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                [selectTabOnClick]="true">
</ts-tab-panel>
<div *ngIf="selectedTabId === 1">
    <ts-layout mode="flex" class="selectWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
        <ts-layout-item>
            <ts-h4 [weight]="600">{{'orders-list.columns.status' | translate}}</ts-h4>
        </ts-layout-item>
        <ts-layout-item>
            <ts-select *ngIf="toBeProduced == 1" primaryText="{{'orders-list.columns.status' | translate}}" 
                        [enableSearch]="false"
                        [clearable]="false"
                        [data]="plannedStatuses"
                        width="204px"
                        [value]="plannedStatuses[orderGroup?.status-1]"
                        (valueChange)="plannedStatusChanged($event)">
            </ts-select>
            <ts-select *ngIf="toBeProduced == 2" primaryText="{{'orders-list.columns.status' | translate}}"
                        [enableSearch]="false" 
                        [clearable]="false"
                        width="204px"
                        [data]="productionStatuses"
                        [value]="productionStatuses[orderGroup?.status-3]"
                        (valueChange)="productionStatusChanged($event)">
            </ts-select>
        </ts-layout-item>
    </ts-layout>
    <ts-layout class="dataWrapper" mode="flex" tsFxLayout="column" tsFxLayoutAlign="start start" padding="16px" gap="0px 0px">
        <ts-layout-item > 
            <ts-h4 [weight]="600">{{'orders-list.orderStatus.registry' | translate}}</ts-h4>
        </ts-layout-item>
        <ts-layout-item class="centralInfo" padding="0px 0px">
            <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
                <ts-layout-item class="infoItem"  padding="0px 0px">
                    <ts-sub-rg>{{'orders-list.columns.code' | translate}}</ts-sub-rg>
                    <span *ngIf="orderGroup?.code">{{orderGroup?.code}}</span>
                    <span *ngIf="!orderGroup?.code">{{'common.notAvailable' | translate}}</span>                    
                </ts-layout-item>
                <ts-layout-item class="infoItem">
                    <ts-sub-rg>{{'orders-list.columns.date' | translate}}</ts-sub-rg>
                    <span *ngIf="orderGroup?.date">{{orderGroup?.date | date:'shortDate'}}</span>
                    <span *ngIf="!orderGroup?.date">{{'common.notAvailable' | translate}}</span>             
                </ts-layout-item>
            </ts-layout>
            <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
                <ts-layout-item class="infoItem">
                    <ts-sub-rg>{{'orders-list.orderInfo.quantityDistribution' | translate}}</ts-sub-rg>
                    <span *ngIf="orderGroup?.quantityDistribution">{{quantityDistributionArray[orderGroup?.quantityDistribution - 1].label}}</span>
                    <span *ngIf="!orderGroup?.quantityDistribution">{{'common.notAvailable' | translate}}</span>
                </ts-layout-item>
                <ts-layout-item class="infoItem">
                    <ts-sub-rg>{{'orders-list.orderInfo.timeDistribution' | translate}}</ts-sub-rg>
                    <span *ngIf="orderGroup?.timeDistribution">{{timeDistributionArray[orderGroup?.timeDistribution - 1].label}}</span>
                    <span *ngIf="!orderGroup?.timeDistribution">{{'common.notAvailable' | translate}}</span>      
                </ts-layout-item>
            </ts-layout>
            <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
                <ts-layout-item class="infoItem">
                    <ts-sub-rg>{{'orders-list.orderInfo.target' | translate}}</ts-sub-rg>
                    <span *ngIf="orderGroup?.target">{{formatDecimal(orderGroup?.target,orderGroup?.Orders[0]?.Product?.ProductionUnit?.decimal || 0) + ' ' + (orderGroup?.Orders[0]?.Product?.ProductionUnit?.unit || '')}}</span>
                    <span *ngIf="!orderGroup?.target">{{'common.notAvailable' | translate}}</span>      
                </ts-layout-item>
            </ts-layout>
            <ts-layout *ngIf="hasJsonInfo()" mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start" gap="16px 16px" padding="0px 0px">
                <ts-layout-item  gap="16px 16px" class="additionalInfo">
                    <ts-sub-rg>{{'orders-list.additional-info' | translate}}</ts-sub-rg>
                    <div *ngFor="let item of toJSON(orderGroup?.jsonInfo) | keyvalue" class="additionalInfoItem">
                        <span>{{item.key}}</span>  
                        <span>{{item.value}}</span>
                    </div>
                </ts-layout-item>
            </ts-layout>
        </ts-layout-item>
    </ts-layout>
</div>
<div *ngIf="selectedTabId === 2">
    <ts-layout  mode="flex" class="selectWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
        <ts-layout-item>
            <ts-h4 [weight]="600">{{'orders-list.orderInfo.groupTitle' | translate:{'code': orderGroup.code} }}</ts-h4>
        </ts-layout-item>
    </ts-layout>
    <ts-layout class="dataWrapper" mode="flex"  tsFxLayout="column" tsFxLayoutAlign="start start"  padding="16px" gap="8px 0px">
        <ts-layout-item mode="flex" tsFxLayout="column" *ngFor="let order of orderGroup.Orders; let i = index;" class="orderInfo" padding="0px 0px" gap="8px 0px">
            <ts-sub-rg weight="700">{{ i +1 }}. {{ order.code }}</ts-sub-rg>
            <ts-layout mode="flex" class="orderRow" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px">
                <ts-layout-item class="orderInfoItem" gap="0px 0px">
                    <ts-sub-rg>{{'orders-list.columns.target'  | translate}}</ts-sub-rg>
                </ts-layout-item>
                <ts-layout-item class="orderInfoItem" gap="0px 0px">
                   <span>{{ formatDecimal(order?.target,order?.Product?.ProductionUnit?.decimal || 0) + ' ' + order?.Product?.ProductionUnit?.unit }}</span>
                </ts-layout-item>
            
                <ts-layout-item class="orderInfoItem" gap="0px 0px">
                    <ts-sub-rg>{{'orders-list.columns.product-description' | translate}}</ts-sub-rg>
                </ts-layout-item>
                <ts-layout-item class="orderInfoItem" gap="0px 0px">
                    <span>{{order.Product.code + ' - ' + order.Product.name}}</span>
                </ts-layout-item>
            </ts-layout>
            <ts-layout mode="flex" class="orderRow" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px">
                <ts-layout-item class="orderInfoItem">
                    <ts-sub-rg>{{'orders-list.columns.date' | translate}}</ts-sub-rg>
                </ts-layout-item>                
                <ts-layout-item class="orderInfoItem">
                        <span>{{ order.deliveryDate | date:'shortDate'}}</span>
                </ts-layout-item>                
                <ts-layout-item class="orderInfoItem">
                    <ts-sub-rg>{{'orders-list.orderInfo.device' | translate}}</ts-sub-rg>
                </ts-layout-item>
                <ts-layout-item class="orderInfoItem">
                    <span>{{ concatDevices(order?.Devices)}}</span>
                </ts-layout-item>
            </ts-layout>
        </ts-layout-item>
    </ts-layout>
</div>