import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Renderer2, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { PlantInstance } from '../../../models/plant.model';
import { Drawer, FontService, NotificationConfig, NotificationService, OverlaySpinnerService } from '@vapor/angular-ui';
import { faPlus, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { ResourcesService } from '../../../services/resources.service';
import { ResourceTree, ResourceType} from '../../../models/resources.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Subscription } from 'rxjs';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { NavigationStart, Router } from '@angular/router';
import { ResourceDrawerComponent } from '../../ui/resource-drawer/resource-drawer.component';
import { LoaderService } from '../../../services/loader.service';

interface ListResource {
    id: number;
    parentId: number;
    resource: ResourceType;
    code: string;
    description: string;
    keyId?: number;
    parentKeyId?: number
}

@Component({
    selector: 'app-companies',
    templateUrl: './resource.component.html',
    styleUrls: ['./resource.component.scss'],
    providers: [
        NotificationService,
    ],    
    encapsulation: ViewEncapsulation.None
})

export class ResourcesComponent implements OnInit {
    @ViewChild('resourcesList', { static: false, read: ElementRef }) ListRef!: ElementRef;
    @ViewChild('resourceTemplate', { static: false }) resourceTemplate: TemplateRef<any>;
    @ViewChild('resourceDrawerBottomBarTemplate', { static: false }) drawerResourceBottomBarTemplate: TemplateRef<any>;
    @ViewChild('resourceDrawer', { static: false }) resourceDrawer: ResourceDrawerComponent;    
    @ViewChild('newResourceTooltip') newResourceTooltip: ElementRef;

    company: CompanyInstance;
    selectedCompanyId: number;
    selectedPlantId: number;
    plants: PlantInstance[] = [];
    selectedPlant: PlantInstance;
    plantValue: string;
    resourceTree: ResourceTree = [];
    resources: ListResource[] = [];
    columns: TsTreeListColumn[] = [];
    resourceTypeStrings: any[] = [];
    freeDevices: ListResource[] = [];
    ownedDevices: ListResource[] = [];
    departments: ListResource[] = [];
    private _subscriptions: Subscription[] = [];
    listHeight = '100vh';
    showNewResourceTooltip = false;
    lastShowNewResourceTooltip = false;
    drawerMode = '';
    drawerResource: ListResource = null;

    constructor(
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private readonly _notification: NotificationService,       
        public _company: CompanyService,        
        private _font: FontService,
        private _spinner: OverlaySpinnerService,    
        private _resourcesService: ResourcesService,
        private _drawer: Drawer,
        private readonly _router: Router,
        private _loaderService: LoaderService,
        private renderer: Renderer2
    ) {
        this._font.addIcon(faPlus, faPencil);
        this.renderer.listen('window', 'click', this.tooltipClickHandler);
        this.renderer.listen('window', 'contextmenu', this.tooltipClickHandler);
        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this._drawer.collapse();
            }
        });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {
        this._translate.stream([
            'devices.resources',
            'resources.subtitle',
            'resources.device',
            'resources.workcenter',
            'resources.department',    
            'resources.type',    
            'resources.resource',    
            'orders-list.search'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['devices.resources']);
            this._sidebar.setSelected('resources');
            this.resourceTypeStrings = [
                {
                    id: ResourceType.device,
                    label: translations['resources.device']
                },
                {
                    id: ResourceType.department,
                    label: translations['resources.department']
                },
                {
                    id: ResourceType.workCenter,
                    label: translations['resources.workcenter']
                },
            ];

            
            this.columns = [
                { // id
                    caption: 'id',
                    dataField: 'id',
                    showColumn: false,
                },
                { // description
                    headerPlaceholder: translations['orders-list.search'],
                    dataField: 'description',
                    caption: translations["resources.resource"],
                    cellTemplate: 'descriptionType',
                    width: 500,
                    allowEditing: false,
                    allowSorting: false,                    
                    lkpKeyField: 'description',
                    lkpResultField: 'label',
                    dataType: 'string',
                },
                { // resource type
                    caption: translations["resources.type"],
                    dataField: 'resource',
                    width: 400,
                    cellTemplate: 'resourceType',
                    allowEditing: false,
                    allowSorting: false,
                    allowFiltering: false,
                    lkpDataSource: this.resourceTypeStrings,                               
                    lkpKeyField: 'id',
                    lkpResultField: 'label',                 
                },
                { 
                    caption: "",
                    dataField: '',
                    showColumn: true,
                    allowEditing: false,
                    allowSorting: false,
                    allowFiltering: false,                 
                },
                { // actions
                    dataField: '',
                    caption: '',
                    cellTemplate: 'actionsType',
                    alignment: 'center',
                    width: 120,
                    allowFiltering: false,
                    allowResizing: false,
                    allowFixing: false,
                    allowSorting: false,
                    allowEditing: false
                },                
            ];            
        });

        this.setupDrawer();

        const companyChangeSubscription =
        this._company.changeCompanyEmitted$.subscribe(company => {
            this.companyChanged(company);
        });
         this._subscriptions.push(companyChangeSubscription);

        this.selectedCompanyId = parseInt(localStorage.getItem('companyId'));
        this.selectedPlantId = parseInt(localStorage.getItem('plantId'));
        
        try {
            this.company = await this._company.getCompany(this.selectedCompanyId);
            this.plants = this.company.Plants.map(plant => {
                return {
                    ...plant,
                    val: plant.description
                }
            });
            if (!this.selectedPlantId){
                await this.handlePlantChange(this.plants[0], false)
            } else {                    
                await this.loadData(false);
            }
            this.createTree();
            
        } catch(err) {
            this.catchError('Error while initiliaze table');
        } finally {
            this._spinner.removeOverlay();
        }
    }

    ngAfterContentChecked(): void {
        if (this.ListRef) {
            const offsetTop = this.ListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.listHeight = `calc(100vh - ${offsetTop}px - 32px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }    

    private catchError(error: string) {
        console.error(error);
        this._translate.stream([
            'orders-list.filters.error',
        ]).subscribe((translations) => {
            const config: NotificationConfig = {
                content: translations['orders-list.filters.error'],
                type: 'toast',
                style: 'error', 
                timeout: 5000,
                position: 'right',
            };
            this._notification.show(config);
        });
    }

    tooltipClickHandler = (e: Event) => {
        if (this.lastShowNewResourceTooltip) {
            this.showNewResourceTooltip = false;
        }
        this.lastShowNewResourceTooltip = this.showNewResourceTooltip;
    };


    async companyChanged(company: CompanyInstance): Promise<void> {
        this.company = company;
        this.plants = this.company.Plants.map(plant => {
            return {
                ...plant,
                val: plant.description
            }
        });
        await this.handlePlantChange(this.plants[0], true);
    }    

    async handlePlantChange(plant: PlantInstance, showSpinner: boolean): Promise<void> {
        this.selectedPlantId = plant.id;
        localStorage.setItem('plantId', this.selectedPlantId.toString());
        this._drawer.collapse();
        await this.loadData(showSpinner);
        this.createTree();
    }    

    createDepartment() {
        // Close tooltip
        this.showNewResourceTooltip = false;

        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.drawerMode = 'new';

        this.freeDevices =  this.resources.filter(res => res.resource == ResourceType.device && res.parentId == null);
        this.drawerResource = JSON.parse(JSON.stringify({
            id: 0,
            parentId: null,
            resource: ResourceType.department,
            code: '',
            description: ''             
        }));

        //this.resourceDrawer.reset(ResourceType.department,'new');        

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('resources.actions.createDepartment'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            contentTemplate: this.resourceTemplate,
            bottomBarTemplate: this.drawerResourceBottomBarTemplate,            
            width: (300 * 2) + (16 * 2) + 32,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Create department drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    createWorkCenter() {
        // Close tooltip
        this.showNewResourceTooltip = false;
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.departments =  this.resources.filter(res => res.resource == ResourceType.department);
        this.freeDevices =  this.resources.filter(res => res.resource == ResourceType.device && res.parentId == null);

        this.drawerResource = JSON.parse(JSON.stringify({
            id: 0,
            parentId: null,
            resource: ResourceType.workCenter,
            code: '',
            description: ''             
        }));        

        this.drawerMode = 'new';
        // Open drawer
        this._drawer.open({
            title: this._translate.instant('resources.actions.createWorkCenter'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            contentTemplate: this.resourceTemplate,
            bottomBarTemplate: this.drawerResourceBottomBarTemplate,
            width: (300 * 2) + (16 * 2) + 32,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Create workcenter drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    private onResourceEditClicked(dev) {
        const resource = this.resources.filter(res => res.id == dev.resourceId && res.resource == dev.type)[0];
        this.drawerResource = JSON.parse(JSON.stringify(resource));
        let title: string = '';

        this.drawerMode = 'edit';

        this.departments =  this.resources.filter(res => res.resource == ResourceType.department);
        this.freeDevices =  this.resources.filter(res => res.resource == ResourceType.device && res.parentId == null);
        this.ownedDevices =  this.resources.filter(res => res.resource == ResourceType.device && res.parentKeyId == resource.keyId);

        switch (resource.resource) {
            case ResourceType.workCenter:
                title = this._translate.instant('resources.actions.editWorkCenter');
                break;
                case ResourceType.department:
                title = this._translate.instant('resources.actions.editDepartment');
                break;
            default:
                break;
        }
        // Close tooltip
        this.showNewResourceTooltip = false;

        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        // Open drawer
        this._drawer.open({
            title,
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            contentTemplate: this.resourceTemplate,
            bottomBarTemplate: this.drawerResourceBottomBarTemplate,            
            width: (300 * 2) + (16 * 2) + 32,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Edit resource drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });

    }    

    private onCancelDrawerClicked () {
        this._drawer.collapse();
    }

    private createTree = () => {
        let i = 0;
        if (this.resourceTree.length == 0)
            return;
        this.resourceTree.forEach(res => {
            i++;
            this.resources.push({
                id: res.id,
                parentId: null,
                resource: res.resource,
                code: res.code,
                description: (res.resource === ResourceType.department || res.resource === ResourceType.workCenter) ? res.description : res.label, 
                keyId: i,
                parentKeyId: null
            })
             if (res.resource == ResourceType.workCenter) {
                const wcId = i;
                this.resources = [...this.resources, ...res.Devices.map(
                                    dev => ({ 
                                            id: dev.id,
                                            parentId: res.id,
                                            resource: ResourceType.device,
                                            code: dev.code,
                                            description: dev.label,
                                            keyId: i++,
                                            parentKeyId: wcId
                                            }))]
            } else if (res.resource == ResourceType.department){
                const depId = i;
                res.Devices.forEach(dev => {
                    this.resources.push({
                        id: dev.id,
                        parentId: res.id,
                        resource: ResourceType.device,
                        code: dev.code,
                        description: dev.label, 
                        keyId: ++i,
                        parentKeyId: depId
                    })                        
                });
                res.WorkCenters.forEach(wc => {
                    i++;
                    this.resources.push({
                        id: wc.id,
                        parentId: res.id,
                        resource: ResourceType.workCenter,
                        code: wc.code,
                        description: wc.description, 
                        keyId: i,
                        parentKeyId: depId
                    }) 
                    const wcId = i;
                    this.resources = [...this.resources, ...wc.Devices.map(
                        dev => ({ 
                                id: dev.id,
                                parentId: wc.id,
                                resource: ResourceType.device,
                                code: dev.code,
                                description: dev.label,
                                keyId: ++i,
                                parentKeyId: wcId
                                }))]
                });                
            }
        });

        this.resources = [...this.resources];
    }

    private async loadData(showSpinner: boolean) {
        this.resourceTree = [];
        this.resources = [];
        if (showSpinner) this._spinner.show({ type: "spinner" });
        try {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId) || this.plants[0];
            if (!this.selectedPlant) {
                throw new Error();
            }
            this.plantValue = this.selectedPlant.description;
            this.resourceTree = await this._resourcesService.getResources(this.selectedPlantId);
        } catch(err) {
            this.catchError(err);
        }finally {
            if (showSpinner) this._spinner.removeOverlay(); 
        }
    }

    private setupDrawer() {
        const toggleSubscription =
            this._drawer.toggle$.subscribe((toggleValue: boolean) => {
                // Close drawers when they are collapsed, in order to clean up the resources
                if (!toggleValue) {
                    // Wait for the collapse animation
                    setTimeout(() => {
                        // Close the drawer
                        this._drawer.close();
                    }, 550);
                }
            });
        this._subscriptions.push(toggleSubscription);

        const closeSubscription =
            this._drawer.close$.subscribe((drawerInfo: DrawerInstanceInfo) => {
                console.info(`Drawer closed: ${drawerInfo.drawerId}`);
            });
        this._subscriptions.push(closeSubscription);
    }

    async onResourceDrawerSaveClick() {
        if(!this.resourceDrawer.formValid) return;
        this._loaderService.startLoader();
        try {
            const result = await this.resourceDrawer.saveNewResource(this.selectedPlantId);
            if(result){
                await this.loadData(false);
                this.createTree();
                const config: NotificationConfig = {
                    content: this._translate.instant(result.resource == ResourceType.department ?
                                                     this._translate.instant('resources.actions.department-created',{code: result.code, description: result.description}) : 
                                                     this._translate.instant('resources.actions.workcenter-created',{code: result.code, description: result.description})),
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            } else {
                const config: NotificationConfig = {
                    content: this._translate.instant('resources.actions.failure'),
                    type: 'toast',
                    style: 'error',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            }
        } catch (error) {
            const config: NotificationConfig = {
                content: this._translate.instant('resources.actions.failure'),
                type: 'toast',
                style: 'error',
                timeout: 5000,
                position: 'right',
            };
            this._notification.show(config);
        } finally {
            this._loaderService.stopLoader();
        }
        this._drawer.collapse();
    }

    async onResourceDrawerEditClick() {
        if(!this.resourceDrawer.formValid) return;
        this._loaderService.startLoader();
        try {
            const result = await this.resourceDrawer.editResource();
            if(result){
                await this.loadData(false);
                this.createTree();                
                const config: NotificationConfig = {
                    content: this._translate.instant(result.resource == ResourceType.department ?
                        this._translate.instant('resources.actions.department-updated',{code: result.code, description: result.description}) : 
                        this._translate.instant('resources.actions.workcenter-updated',{code: result.code, description: result.description})),
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            } else {
                const config: NotificationConfig = {
                    content: this._translate.instant('resources.actions.failure'),
                    type: 'toast',
                    style: 'error',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            }
        } catch (error) {
            const config: NotificationConfig = {
                content: this._translate.instant('resources.actions.failure'),
                type: 'toast',
                style: 'error',
                timeout: 5000,
                position: 'right',
            };
            this._notification.show(config);
        }finally {
            this._loaderService.stopLoader();
        }
        this._drawer.collapse();
    }
}
