import { CmsComponent } from "./cms/cms.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ErrorsComponent } from "./components/pages/errors/errors.component";
import { ProductsComponent } from "./components/pages/products/products.component";
import { NewProductComponent } from "./components/pages/new-product/new-product.component";
import { DevicesComponent } from "./components/pages/devices/devices.component";
import { NewDeviceComponent } from "./components/pages/new-device/new-device.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { TagsComponent } from "./components/pages/tags/tags.component";
import { ShiftsComponent } from "./components/pages/work-shift/work-shift.component";
import { UsersComponent } from "./components/pages/users/users.component";
import { QualityControlsComponent } from "./components/pages/quality-controls/quality-controls.component";
import { ControlComponent } from "./components/pages/control/control.component";
import { CategoriesComponent } from "./components/pages/categories/categories.component";
import { ProcessDataComponent } from "./components/pages/process-data/process-data.component";
import { OperatorsComponent } from "./components/pages/operators/operators.component";
import { OrdersListComponent } from "./components/pages/orders-list/orders-list.component";
import { FamiliesComponent } from "./components/pages/families/families.component";
import { WorksComponent } from "./components/pages/works/works.component";
import { NewFamilyComponent } from "./components/pages/new-family/new-family.component";
import { NewWorkComponent } from "./components/pages/new-work/new-work.component";
import { ResourcesComponent } from "./components/pages/resource/resource.component";
import { UnitsComponent } from "./components/pages/units/units.component";
import { NewUnitComponent } from "./components/pages/new-unit/new-unit.component";
import { ProductSupplyType } from "./models/product.model";
import { OrderEditorComponent } from "./components/pages/order-editor/order-editor.component";
import { OAuthCallbackComponent } from "./components/pages/oauth-callback/oauth-callback.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: CmsComponent,
    children: [
      {
        path: "stops",
        component: ErrorsComponent,
      },
      {
        path: "tags",
        component: TagsComponent,
      },
      {
        path: "categories",
        component: CategoriesComponent,
      },
      {
        path: "products",
        component: ProductsComponent,
      },
      {
        path: "products/newbuy",
        component: NewProductComponent,
        data: {
          supplyType: ProductSupplyType.buy,
          isDuplicate: false,
          isEditing: false,
        },
      },
      {
        path: "products/newmake",
        component: NewProductComponent,
        data: {
          supplyType: ProductSupplyType.make,
          isDuplicate: false,
          isEditing: false,
        },
      },
      {
        path: "products/edit/:id",
        component: NewProductComponent,
        data: { supplyType: undefined, isDuplicate: false, isEditing: true },
      },
      {
        path: "products/duplicate/:id",
        component: NewProductComponent,
        data: { supplyType: undefined, isDuplicate: true, isEditing: false },
      },
      {
        path: "families",
        component: FamiliesComponent,
      },
      {
        path: "families/new",
        component: NewFamilyComponent,
        data: { editMode: false },
      },
      {
        path: "families/edit/:id",
        component: NewFamilyComponent,
        data: { editMode: true },
      },
      {
        path: "works",
        component: WorksComponent,
      },
      {
        path: "works/new",
        component: NewWorkComponent,
        data: { editMode: false },
      },
      {
        path: "works/edit/:id",
        component: NewWorkComponent,
        data: { editMode: true },
      },
      {
        path: "devices",
        component: DevicesComponent,
      },
      {
        path: "devices/new",
        component: NewDeviceComponent,
      },
      {
        path: "resources",
        component: ResourcesComponent,
      },
      {
        path: "process-data",
        component: ProcessDataComponent,
      },
      {
        path: "shifts",
        component: ShiftsComponent,
      },
      {
        path: "users",
        component: UsersComponent,
      },
      {
        path: "orders-list",
        component: OrdersListComponent,
      },
      {
        path: "orders/new",
        component: OrderEditorComponent,
        data: { editMode: false },
      },
      {
        path: "orders/edit/:id",
        component: OrderEditorComponent,
        data: { editMode: true },
      },
      {
        path: "quality-controls",
        component: QualityControlsComponent,
      },
      {
        path: "quality-controls/:id/details",
        component: ControlComponent,
      },
      {
        path: "operators",
        component: OperatorsComponent,
      },
      {
        path: "units",
        component: UnitsComponent,
      },
      {
        path: "units/new",
        component: NewUnitComponent,
        data: { editMode: false },
      },
      {
        path: "units/edit/:id",
        component: NewUnitComponent,
        data: { editMode: true },
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "callback",
    component: OAuthCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
