import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { UserInstance } from '../../../models/user.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { OperatorService } from '../../../services/operator.service';
import { OperatorInstance, OperatorInterface } from '../../../models/operator.model';
import { Drawer, FontService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { faPencil, faWindowMaximize, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { OperatorDrawerComponent } from '../../ui/operator-drawer/operator-drawer.component';
import { ColumnFilterOperation, ColumnFilterTargetValues } from '@vapor/angular-ui-extra/table-common-internal';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-users',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OperatorsComponent implements OnInit {
    columns: TsTreeListColumn[] = [];
    isAdmin = false;
    operators: OperatorInstance[];
    tmpOperators: OperatorInstance[];
    selectedCompany: CompanyInstance;
    selectedCompanyId: number;
    selectedPlant: PlantInstance;
    selectedPlantId: number;
    USER_ROLE: typeof UserRole = UserRole;
    current: UserRole;
    devices: DeviceInstance[];
    plants: PlantInstance[];
    plantValue: string;
    moduleActivated = false;
    lockRequest = false;
    private _subscriptions: Subscription[] = [];

    selectedOperator: OperatorInstance;
    operatorsListHeight = '100vh';


    @ViewChild('operatorsList', { static: false, read: ElementRef }) operatorsListRef!: ElementRef;
    @ViewChild('operatorInfoTemplate', { static: true }) operatorInfoTemplate: TemplateRef<any>;
    @ViewChild('operatorInfoBottomBarTemplate', { static: true }) operatorInfoBottomBarTemplate: TemplateRef<any>;
    @ViewChild('newOperatorTemplate', { static: false }) newOperatorTemplate: TemplateRef<any>;
    @ViewChild('editOperatorTemplate', { static: false }) editOperatorTemplate: TemplateRef<any>;
    @ViewChild('operatorDrawerBottomTemplate', { static: false }) operatorDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('operatorDrawer', { static: false }) operatorDrawer: OperatorDrawerComponent;


    constructor(
        private _company: CompanyService,
        private _operators: OperatorService,
        private _device: DeviceService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _drawer: Drawer,
        private _popup: PopupService,
        private _translate: TranslateService,
        private _font: FontService,
        private _spinner: OverlaySpinnerService,
        public _auth: AuthService,
        private _router: Router
    ) {
        this._font.addIcon(faPencil, faWindowMaximize, faTrashAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {
        await this._auth.loadState();
        this._spinner.show({ type: "spinner" });
        const translationSubscription =
            this._translate.stream([
                'operators.title',
                'users.columns.firstName',
                'users.columns.lastName',
                'users.columns.enabled',
                'orders-list.search',
                'orders-list.columns.code',
                'orders-list.columns.devices'
            ])
                .subscribe((translations) => {
                    this._navbar.setTitle(translations['operators.title']);
                    this._sidebar.setSelected('operators');

                    const defaultColumn: TsTreeListColumn = {
                        dataField: '',
                        headerPlaceholder: translations['orders-list.search'],
                        allowFiltering: true,
                        allowEditing: false,
                        allowSorting: false,
                    };

                    this.columns = [
                        { // Last Name
                            ...defaultColumn,
                            dataField: 'surname',
                            caption: translations["users.columns.lastName"],
                            dataType: 'string',
                        },
                        { // First Name
                            ...defaultColumn,
                            dataField: 'name',
                            caption: translations["users.columns.firstName"],
                            dataType: 'string',
                        },
                        { // Code
                            ...defaultColumn,
                            dataField: 'code',
                            caption: translations["orders-list.columns.code"],
                            dataType: 'string',
                        },
                        { // Devices
                            ...defaultColumn,
                            dataField: 'deviceLabels',
                            caption: translations["orders-list.columns.devices"],
                            dataType: 'object',
                            cellTemplate: "tplDevices",
                            width: 280,
                            calculateFilterExpression: this._operatorsDevicesFilter,
                        },
                        { // actions
                            ...defaultColumn,
                            caption: '',
                            alignment: 'center',
                            width: 200,
                            allowFiltering: false,
                            allowResizing: false,
                            allowFixing: false,
                            cellTemplate: 'tplActions',
                        },
                    ]
                });

        this._subscriptions.push(translationSubscription);

        this.isAdmin = this._auth.user.role === UserRole.admin;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.lockRequest = true;

        if (this.isAdmin) {
            await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
        } else {
            // if is user get company detailt to get plants
            this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
            // set plants
            this.plants = this.selectedCompany.Plants;
            await this._handleBuildPlantArrayObject(true);
            await this.plantChanged();
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if (this.isAdmin) {
                    this.companyChanged(company);
                }
            });

        this.tmpOperators?.sort((a, b) => a.surname.localeCompare(b.surname)); // Sort by Last Name ASC

        this._subscriptions.push(companyChangeSubscription);
        this._spinner.removeOverlay();
    }

    _operatorsDevicesFilter(filterValue: unknown,
        selectedFilterOperation: ColumnFilterOperation,
        target: ColumnFilterTargetValues) {
        return ["deviceLabels", "contains", filterValue];
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.operatorsListRef) {
            const offsetTop = this.operatorsListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.operatorsListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    showCreateOrUpdateOperatorDrawer(operatorId?: number) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        let _operator: OperatorInstance = null;

        if (!operatorId) {
            _operator = {
                id: null,
                name: '',
                surname: '',
                code: null,
                Devices: []
            };
        } else {
            _operator = this.tmpOperators.find(o => o.id === operatorId);
        }

        this.selectedOperator = _operator;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('operators.title'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: operatorId ? this.editOperatorTemplate : this.newOperatorTemplate,
            bottomBarTemplate: this.operatorDrawerBottomTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New Operator drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });

    }

    async onOperatorFormSubmit() {

        const result: { operator: OperatorInstance, isEditing: boolean } = this.operatorDrawer.onSubmit(); // isEditing is false when creating a new operator

        const operator = result.operator;
        const deviceIds: number[] = operator.Devices.length ? operator.Devices.map(d => d.id) : [];

        try {
            if (result) {
                if (result.isEditing) {
                    const new_operator = await this._operators.updateOperator(
                        operator.id,
                        operator.name,
                        operator.surname,
                        operator.code,
                        deviceIds
                    );
                    if (typeof new_operator === 'number') {
                        this._ui.showNotification(this._translate.instant('operators.error-save'));
                        return;
                    }
                    const opIndex = this.tmpOperators.findIndex(op => op.id === operator.id);
                    this.tmpOperators.splice(opIndex, 1, operator);
                    this.tmpOperators = this.clone(this.tmpOperators);
                    this._ui.showNotification(this._translate.instant('operators.saved'));

                } else {
                    const new_operator = await this._operators.createOperator(
                        operator.name,
                        operator.surname,
                        operator.code,
                        this.selectedPlantId,
                        deviceIds
                    );
                    if (typeof new_operator === 'number') {
                        this._ui.showNotification(this._translate.instant('operators.error-save'));
                        return;
                    }
                    this.operators.unshift(new_operator);
                    this.tmpOperators = this.clone(this.operators);
                    this._ui.showNotification(this._translate.instant('operators.saved'));
                }
            }
        } catch (error) {
            console.error(error);
            this._ui.showNotification(this._translate.instant('operators.error-save'), 'error');
        } finally {
            this.tmpOperators?.sort((a, b) => a.surname.localeCompare(b.surname)); // Sort by Last Name ASC
            this._drawer.close();
        }

    }

    async companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = company.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
            company.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged();
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
            this.plantValue = this.selectedPlant.description;
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
            this.plantValue = this.selectedPlant.description;
        }
    }

    async plantChanged(plant?: PlantInstance) {

        if (plant) {
            this.selectedPlant = plant;
        }

        if (this.selectedPlant) {

            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlantId.toString());

            if (this.selectedPlant.PlantModule && this.selectedPlant.PlantModule.operatorsLogin) {
                this.moduleActivated = true;
                this.operators = await this._operators.getOperators(this.selectedPlantId);
                this.operators = this.operators.filter((operator) => {
                    return operator.id > 0;
                });
                this.operators = this.operators.map(
                    operatorInstance => {
                        const operator: OperatorInterface = {
                            ...operatorInstance,
                            deviceLabels: operatorInstance.Devices?.map((device: DeviceInstance) => device.label)
                        };
                        return operator;
                    }
                );

                this.devices = await this._device.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

                this.tmpOperators = this.clone(this.operators);
                this.tmpOperators?.sort((a, b) => a.surname.localeCompare(b.surname)); // Sort by Last Name ASC

            } else {
                this.moduleActivated = false;
            }
        }
        this.lockRequest = false;
    }

    /**
     * Retrieve the plant list about company
     *
     * @param {number} companyId
     * @returns
     * @memberof UsersComponent
     */
    async getPlants(companyId: number) {
        return await this._company.getPlants(companyId);
    }

    async deleteOperator(operatorId?: number) {
        const _operator = this.tmpOperators.find(o => o.id === operatorId);

        const popupConfig: PopupConfig = {
            title: this._translate.instant('operators.dialog.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('operators.dialog.text', { operator: _operator.name + ' ' + _operator.surname }),
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (btn) => {
            try {
                if (btn === 'primary') {
                    this._spinner.show({ type: "spinner" });
                    const status = await this._operators.deleteOperator(_operator.id);
                    if (status === 200) {
                        const opIndex = this.tmpOperators.findIndex(op => op.id === _operator.id);
                        this.tmpOperators.splice(opIndex, 1);
                        this.tmpOperators = this.clone(this.tmpOperators);
                        this._ui.showNotification(this._translate.instant('operators.deleted'));
                        return;
                    } else {
                        this._ui.showNotification(this._translate.instant('operators.error-delete'), 'error');
                        return;
                    }
                } else {
                    console.log("Delete Canceled")
                    return;
                }
            } catch (error) {
                console.error(error);
                this._ui.showNotification(this._translate.instant('operators.error-delete'), 'error');
                return;
            } finally {
                this._spinner.removeOverlay();
            }

        })
    }

    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    updateSelectedUserPlant(user: UserInstance) {
        user.Plants = [];
        if (user && user.plant && user.plant.id !== -1 && this.plants && this.plants.length > 0) {
            user.Plants[0] = this.plants.find(p => p.id === user.plant.id);
        }
    }

    onOperatorInfoClicked(operator: OperatorInstance) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.selectedOperator = operator;

        this._drawer.open({
            title: this._translate.instant('operators.title'),
            subTitle: operator.code,
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.operatorInfoTemplate,
            bottomBarTemplate: this.operatorInfoBottomBarTemplate
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Operator info drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onCancelDrawerClicked() {
        this._drawer.collapse();
    }
}
