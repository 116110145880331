import { Component, OnInit, OnDestroy, ViewChild, runInInjectionContext, inject, EnvironmentInjector } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { FontService, SelectionModeEnum, UiShellComponent } from '@vapor/angular-ui';
import { InternalUiShellItems, UiShellItems } from '@vapor/angular-utils';

import { UserRole } from '../../../models/user.model';

import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewService, UserView } from '../../../services/view.service';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';

import { faArrowRight, faHand, faClipboardList, faUser, faBadgeCheck, faBox, faSpinner, faComputerClassic, faArrowsV, faArrowsSpin, faBuilding, faBriefcase } from "@fortawesome/pro-regular-svg-icons"

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

    @ViewChild('uiShell') uiShell!: UiShellComponent;
    private environmentInjector = inject(EnvironmentInjector);
    
    private control_details = '/details';
    private _filterSubscription: Subscription = new Subscription();
    private _sideBarSubscription: Subscription = new Subscription();
    private _uiShellSubscription: Subscription = new Subscription();

    companies: CompanyInstance[];
    selectedCompany: CompanyInstance;
    companiesList: string[] = [];
    company: string;
    sidebarIds: { id: string, label: string }[];
    selectedSidebar: string;

    languages = [
        { code: 'en', locale: 'en-US', label: 'English' },
        { code: 'it', locale: 'it-IT', label: 'Italiano' },
        { code: 'de', locale: 'de-DE', label: 'Deutsch' },
        { code: 'fr', locale: 'fr-FR', label: 'Français' },
        { code: 'el', locale: 'el-GR', label: 'Ελληνικά' }
    ];

    currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    selectedLanguage = this.languages.find(l => l.code === this.currentLang);
    showMenu = false;

    showLanguages = false;

    isAdmin = false;
    isOrdersCore = false;
    isManager = false;
    isOrdersManager = true; // TODO implement module validation
    isChildPage: boolean;
    isWorkProcessManager: boolean = false;

    userPopoverVisible: boolean = false;
    selectionMode = SelectionModeEnum.None;

    routes: UiShellItems = [];

    constructor(
        public sidebar: SidebarService,
        private _router: Router,
        public _auth: AuthService,
        public navbar: NavbarService,
        public _translate: TranslateService,
        public _viewService: ViewService,
        public _company: CompanyService,
        private _font: FontService
    ) {
        // fa-hand, arrows-spin
        this._font.addIcon(faHand, faClipboardList, faUser, faBadgeCheck, faBox, faComputerClassic, faSpinner, faArrowRight, faArrowsV, faArrowsSpin, faBuilding, faBriefcase);
    }

    async ngOnInit() {

        await this._auth.loadState();
        this._filterSubscription = this._auth.onNavbarChange.subscribe(() => {
            this.navbarChange();
        });

        if (!!this._auth && !!this._auth.user) {
            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;

            this.isOrdersManager = this.isAdmin || true; // TODO: implement module validation

            // this._viewService.userCanView(this._auth.user, UserView.ordersCore)
            //                 .then( userCanViewOrdersCore => {
            //                     this.isOrdersCore = userCanViewOrdersCore;
            //                 });
            this._viewService.userCanView(this._auth.user, UserView.workProcess)
                .then(userCanViewWorkProcess => {
                    this.isWorkProcessManager = userCanViewWorkProcess;
                });
            if (this.isAdmin) {
                await this.initCompanyList();
                this.updateSelectedCompany(this.selectedCompany);
            }
        }

        this.updateRoutes();

        this.initUiShell();

    }

    initUiShell(): void {
        if (this.uiShell) {
            this._sideBarSubscription =
                this.sidebar.selectedSidebar$.subscribe((selectedSidebar) => {
                    (this.uiShell as any).store.handleSubItemSelection(this.sidebarIds?.find(s => s.label === selectedSidebar)?.id);
                    this.selectedSidebar = selectedSidebar;
                })

            runInInjectionContext(this.environmentInjector, () => {
                this._uiShellSubscription =
                    toObservable((this.uiShell as any).store.items).subscribe((items: InternalUiShellItems) => {
                        this.sidebarIds = items.map(s => {
                            return {
                                id: s.id,
                                label: s.label,
                            }
                        });
                        (this.uiShell as any).store.handleSubItemSelection(this.sidebarIds?.find(s => s.label === this.selectedSidebar)?.id);
                    });
                });
        }
    }

    private async fetchCompanies() {
        this.companies = await this._company.getCompanies();
    }

    private async initCompanyList() {
        await this.fetchCompanies();

        const selectedCompanyId = Number(localStorage.getItem('companyId'));

        if (selectedCompanyId) {
            this.selectedCompany = this.companies.find(c => c.id === selectedCompanyId);
        } else {
            this.selectedCompany = this.companies[0];
        }

        localStorage.setItem('companyId', this.selectedCompany.id.toString());
    }

    clone(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }

    ngOnDestroy() {
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
        if (this._sideBarSubscription) {
            this._sideBarSubscription.unsubscribe();
        }
        if (this._uiShellSubscription) {
            this._uiShellSubscription.unsubscribe();
        }
    }

    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isLoginPage(): boolean {
        const isLogin = this._router.isActive('/login', true);
        return isLogin;
    }

    setLanguage(locale: string) {
        if (this._translate.currentLang === locale) {
            return;
        }

        localStorage.setItem('lang', locale);

        window.location.reload();
    }

    logout() {
        this.selectedCompany = null;
        this._auth.logout();
    }
    toggleMenu() {
        this.showMenu = !this.showMenu;
    }

    isControlPage() {
        const url = this._router.url;
        this.isChildPage = url.includes(this.control_details);
    }

    goBack() {
        this._router.navigate(['/quality-controls']);
    }

    private async navbarChange() {
        this.selectedCompany = null;
        this.isAdmin = this._auth.user.role === UserRole.admin;
        // this.isOrdersCore = this.isAdmin || this._auth.user.role === UserRole.manager;
        this.isManager = this._auth.user.role === UserRole.manager;
        this.isOrdersManager = this.isAdmin || true;  // TODO: implement module validation
        this.userPopoverVisible = false;

        // this._viewService.userCanView(this._auth.user, UserView.ordersCore)
        //                     .then( userCanViewOrdersCore => {
        //                         this.isOrdersCore = userCanViewOrdersCore;
        //                     });
        this._viewService.userCanView(this._auth.user, UserView.workProcess)
            .then(userCanViewWorkProcess => {
                this.isWorkProcessManager = userCanViewWorkProcess;
            });
        if (this.isAdmin) {
            await this.initCompanyList();
        }

        this.initUiShell();
    }

    updateSelectedCompany(event: any) {
        const prev_company_id = Number(localStorage.getItem('companyId'));
        const _company = this.companies.find(c => c.id === event.id);

        this.selectedCompany = _company;

        localStorage.setItem('companyId', _company.id.toString());

        if (prev_company_id !== _company.id) {
            this._company.emitChangeCompany(_company);
        }
    }

    onLanguageChange(event: any) {
        this.setLanguage(event.code);
    }

    showUserProfilePopOver() {
        this.userPopoverVisible = !this.userPopoverVisible;
    }

    private updateRoutes() {

        this._translate.stream([
            'orders-list.title',
            'products.works.title',
            'stopReasons.stops',
            'stopReasons.title',
            'tags.title',
            'categories.title',
            'products.title',
            'productionUnits.title',
            'productsFamily.title',
            'devices.title',
            'devices.active',
            'devices.resources',
            'devices.uninitialized',
            'devices.processdata',
            'schedules.schedules',
            'quality-controls.title',
            'users.title',
            'operators.title',
        ]).subscribe(async (translations) => {

            this.routes = [
                {
                    label: translations['orders-list.title'],
                    tooltipLabel: translations['orders-list.title'],
                    icon: 'arrows-spin',
                    children: [
                        {
                            to: '/orders-list',
                            label: translations['orders-list.title'],
                        },
                        {
                            to: '/works',
                            label: translations['products.works.title'],
                        }
                    ]
                },
                {
                    label: translations['stopReasons.stops'],
                    tooltipLabel: translations['stopReasons.stops'],
                    icon: 'hand',
                    children: [
                        {
                            to: '/stops',
                            label: translations['stopReasons.title'],
                        },
                        {
                            to: '/tags',
                            label: translations['tags.title'],
                        },
                        {
                            to: '/categories',
                            label: translations['categories.title'],
                        },
                    ],
                },
                {
                    label: translations['products.title'],
                    tooltipLabel: translations['products.title'],
                    icon: 'box',
                    children: [
                        {
                            to: '/products',
                            label: translations['products.title'],
                        },
                        {
                            to: '/units',
                            label: translations['productionUnits.title'],
                        },
                        {
                            to: '/families',
                            label: translations['productsFamily.title'],
                        },
                    ],
                },
                {
                    label: translations['devices.title'],
                    tooltipLabel: translations['devices.title'],
                    icon: 'computer-classic',
                    children: [
                        {
                            to: '/devices',
                            label: translations['devices.active'],
                        },
                        {
                            to: '/resources',
                            label: translations['devices.resources'],
                        },
                        ...(this.isAdmin ? [
                            {
                                to: '/devices/new',
                                label: translations['devices.uninitialized'],
                            },
                        ] : []),
                        ...((this.isAdmin || this.isManager) ? [
                            {
                                to: '/process-data',
                                label: translations['devices.processdata'],
                            },
                        ] : []),           
                    ],
                },
                {
                    label: translations['schedules.schedules'],
                    tooltipLabel: translations['schedules.schedules'],
                    to: '/shifts',
                    icon: 'clipboard-list',
                },
                {
                    label: translations['quality-controls.title'],
                    tooltipLabel: translations['quality-controls.title'],
                    to: '/quality-controls',
                    icon: 'badge-check',
                },
                {
                    label: translations['users.title'],
                    tooltipLabel: translations['users.title'],
                    icon: 'user',
                    children: [
                        {
                            to: '/users',
                            label: translations['users.title'],
                        },
                        {
                            to: '/operators',
                            label: translations['operators.title'],
                        },
                    ],
                },
            ];
        })

    }

    shouldDisplayRoute(route: any): boolean {
        return route.condition === undefined ? true : route.condition;
    }

    displayAvatarCharacters() {
        if (this._auth.user) {
            if (this._auth.user.lastName && this._auth.user.firstName) {
                return `${this._auth.user.firstName[0]} ${this._auth.user.lastName[0]}`
            } else {
                return this._auth.user.email[0];
            }
        }
    }

    closeSidebarSecondaryPanel() {
        // this._uiShellSubscription = this.uiShell.store.state$.subscribe((state) => {
        //     if (state.toggleSecondaryPanel) {
        //         this.uiShell.store.closeAllPanels();
        //     }
        // });

        // this._uiShellSubscription.unsubscribe();
    }
}