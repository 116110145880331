import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { Utility } from "../../../utils/utility";

/**
 * onChange event returns the selected date and time in the following format: "M/D/YYYY h:mm A"
 */
@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AppDateTimePicker implements OnInit {
    @Input() id: string;
    @Input() primaryText!: string;
    @Input() width!: string;
    @Input() value?: string;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    dateTimeString = '';
    modalTitle = "Select date and time"; //TODO: translate
    valueTemp: Date = new Date();
    modalIsOpen = false;

    constructor(
        private _utility: Utility,
    ) { }

    ngOnInit(): void {
        if (!!this.value) {
            this.valueTemp = new Date(this.value);
            this.dateTimeString = this._utility.formattedDate(this.valueTemp);
        }
    }

    setDate(date: Date) {
        this.valueTemp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    }

    getDate() {
        return this.valueTemp;
    }

    setTime(date: string) {
        this.valueTemp.setHours(Number(date.split(':')[0]), Number(date.split(':')[1]));
    }

    getTime() {
        const hours = ('0' + this.valueTemp.getHours()).slice(-2);
        const minutes = ('0' + this.valueTemp.getMinutes()).slice(-2);
        const formattedTime = `${hours}:${minutes}`;
        return formattedTime;
    }

    onConfirm() {
        this.dateTimeString = this._utility.formattedDate(this.valueTemp);
        this.onChange.emit(this.valueTemp);
        this.modalIsOpen = false;
    }

    onModalOpen() {
        this.modalIsOpen = true;
    }

    onModalClose() {
        this.modalIsOpen = false;
    }
}