import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { UserRole } from '../models/user.model';

import { SidebarService } from '../services/sidebar.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CmsComponent implements OnInit {
    isAdmin = false;
    constructor(
        public sidebar: SidebarService,
        public _auth: AuthService,
    ) {}

    async ngOnInit() {
        await this._auth.loadState();
        if (!!this._auth && !!this._auth.user) {
            this.isAdmin = this._auth.user.role === UserRole.admin;
        } else
            console.error('No auth service/user');
    }
}
