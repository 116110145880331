<ts-tab-panel [panelTabs]="tabs" [(selectedTab)]="selectedTabId" type="secondary" barType="standard" size="small"
    [selectTabOnClick]="true">
</ts-tab-panel>
<div [ngSwitch]="selectedTabId">
    <div *ngSwitchCase="1">
        <form [formGroup]="form" autocomplete="off" class="page-container new-orders-group-drawer mic-drawer">
            <div class="row">
                <ts-h4 [weight]="600">{{'orders-list.group-status' | translate}}</ts-h4>
            </div>
            <div class="row">
                <div class="column">
                    <ts-select [data]="statuses" primaryText="{{'orders-list.columns.status' | translate}}"
                        placeholderText="{{'orders-list.columns.status' | translate}}" [showErrors]="true"
                        formControlName="status" [value]="f.status.value"></ts-select>
                </div>
            </div>
            <div class="empty-row"></div>
            <div class="row">
                <ts-h4 [weight]="600">{{'orders-list.data' | translate}}</ts-h4>
            </div>
            <div class="row">
                <div class="column">
                    <ts-text-box primaryText="{{'orders-list.columns.group-code' | translate}}" [enabled]="!isEditing"
                        placeholderText="{{'orders-list.columns.group-code' | translate}}" formControlName="code"
                        [showErrors]="true" [width]="300"></ts-text-box>
                </div>
                <div class="column">
                    <!--
                        Vapor sets dx-date-box isValid without checking if the control is touched,
                        but this results in always showing an error: the long term solution would be
                        to fix Vapor, meanwhile we check the control ourselves
                    -->
                    <vapor-angular-datepicker height="40px" primaryText="{{'orders-list.columns.date' | translate}}"
                        placeholderText="{{'orders-list.columns.date' | translate}}"
                        [showErrors]="f.date.invalid && f.date.touched" [width]="300"
                        formControlName="date"></vapor-angular-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <div class="ts-input-container" style="width: 300px;">
                        <div class="ts-input-container__header">
                            <div class="ts-input-container__header__primary">
                                <span class="ts-input-container__header__primary__text">
                                    {{'orders-list.columns.quantity-distribution' | translate}}
                                </span>
                            </div>
                            <span class="ts-input-container__header__optional"></span>
                        </div>
                        <div class="ts-input-container__data">
                            <ts-radio name="quantityDistribution" [items]="quantityDistributionItems"
                                [(value)]="quantityDistributionValue" [showErrors]="true"
                                formControlName="quantityDistribution"></ts-radio>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="ts-input-container" style="width: 300px;">
                        <div class="ts-input-container__header">
                            <div class="ts-input-container__header__primary">
                                <span class="ts-input-container__header__primary__text">
                                    {{'orders-list.columns.time-distribution' | translate}}
                                </span>
                            </div>
                            <span class="ts-input-container__header__optional"></span>
                        </div>
                        <div class="ts-input-container__data">
                            <ts-radio name="timeDistribution" [items]="timeDistributionItems"
                                [(value)]="timeDistributionValue" [showErrors]="true"
                                formControlName="timeDistribution"></ts-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <ts-text-box primaryText="{{'orders-list.columns.production-target' | translate}}"
                        placeholderText="{{ 'orders-list.cells.target' | translate:{ 'target': target|number:'1.0-2' } }}"
                        primaryIcon="" [width]="300" [readOnly]="true" [showErrors]="true"></ts-text-box>
                </div>
            </div>
            <div class="empty-row"></div>
            <ts-accordion [allowMulti]="false">
                <ts-accordion-panel title="{{'orders-list.additional-info' | translate}}" [opened]="additionalInfoOpen"
                    (toggle)="onAdditionalInfoToggle($event)">
                    <ng-container formArrayName="jsonInfoArray">
                        <div class="jsonInfoRow" *ngFor="let info of jsonInfoArray.controls; let i = index"
                            [formGroupName]="i.toString()">
                            <ts-text-box primaryText="{{'orders-list.jsonInfo.key' | translate}}"
                                placeholderText="{{'orders-list.jsonInfo.insertKey' | translate}}" [width]="250"
                                formControlName="key"></ts-text-box>
                            <ts-text-box primaryText="{{'orders-list.jsonInfo.value' | translate}}"
                                placeholderText="{{'orders-list.jsonInfo.insertValue' | translate}}" [width]="250"
                                formControlName="value"></ts-text-box>
                            <ts-button type="tertiary alert-dark medium" icon="trash"
                                (click)="deleteJsonInfo(i)"></ts-button>
                        </div>
                    </ng-container>
                    <div class="jsonInfoRow">
                        <ts-button type="tertiary small blue" icon="plus"
                            text="{{'orders-list.jsonInfo.addNew' | translate}}" (click)="addNewJsonInfo()"></ts-button>
                    </div>
                </ts-accordion-panel>
            </ts-accordion>
        </form>
    </div>
    <div *ngSwitchCase="2" class="orders-container">
        <ts-h4 [weight]="600">{{'orders-list.group-info' | translate:{'code': f.code.value} }}</ts-h4>
        <div class="order-item" *ngFor="let order of selectedOrders; let i = index">
            <ts-sub-rg [weight]="700">{{ i + 1 }}. {{ order.code }}</ts-sub-rg><br>
            <div class="order-data">
                <div class="order-row">
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.target' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ 'orders-list.cells.target' | translate:{'target': order.target} }}</span>
                    </div>
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.product-description' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{order.Product.code}} - {{order.Product.name}}</span>
                    </div>
                </div>
                <div class="order-row">
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.date' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ order.date | date:"shortDate" }}</span>
                    </div>
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.devices' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ concatDevices(order.Devices) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>