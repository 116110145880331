// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    api: {
        baseUrl: '',
        url: '',
        urlV2: '',
    },
    workshift: {
        shiftStep: 5
    },
    devextremeLicenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjRmYTk5MWY4LThhODctNGE3ZS05NjhkLTU3MzQ2OGU3MDBkYSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.qnHzJ2LmqLSX4FwuzVBHxRWv5K4h82PCoH+6Tvb9jiSf89g/xZzzSqztq+/SSxPxXtNGfpRFB05QjGqHViGuaO88ogbpQ7uPI3DrpaCozjQ9MmIvRPnDgBX3ne2MBQJRFlBLpQ==',
    tsidEnabled: false,
    mesCloudUrl: 'cms.mes.teamsystem.com'
};
