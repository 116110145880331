import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeviceInstance } from '../../../models/device.model';
import { UserRole } from '../../../models/user.model';
import { ProcessDataInstance, ValueType } from '../../../models/process-data.model';
import { PlantInstance } from '../../../models/plant.model';

import { DeviceService } from '../../../services/device.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { ProcessDataService } from '../../../services/processData.service';


import { Drawer, FontService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { ProcessDataDrawerComponent } from '../../ui/process-data-drawer/process-data-drawer.component';
import { CompanyService } from '../../../services/company.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { CompanyInstance } from '../../../models/company.model';
import { UiService } from '../../../services/ui.service';


@Component({
    selector: 'app-process-data',
    templateUrl: './process-data.component.html',
    styleUrls: ['./process-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProcessDataComponent implements OnInit {
    processData: ProcessDataInstance[] = [];
    columns: TsTreeListColumn[] = [];

    tmpDevices: DeviceInstance[];
    devices: DeviceInstance[];
    plants: PlantInstance[];
    selectedDevice: DeviceInstance;
    selectedPlant: PlantInstance;

    selectedProcess: ProcessDataInstance;

    isAdmin = false;
    valueTypes = [];
    assignedValueTypes = [];

    selectedCompany: CompanyInstance;
    selectedCompanyId: number;

    processListHeight = '100vh';

    isDisabled: boolean = false;

    private _subscriptions: Subscription[] = [];


    @ViewChild('processList', { static: false, read: ElementRef }) processListRef!: ElementRef;
    @ViewChild('editProcessTemplate', { static: false }) editProcessTemplate: TemplateRef<any>;
    @ViewChild('newProcessTemplate', { static: false }) newProcessTemplate: TemplateRef<any>;
    @ViewChild('processDrawerBottomTemplate', { static: false }) processDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('processDrawer', { static: false }) processDrawer: ProcessDataDrawerComponent;

    constructor(
        private _ui: UiService,
        private _device: DeviceService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _drawer: Drawer,
        private _popup: PopupService,
        private _translate: TranslateService,
        private _auth: AuthService,
        private _processDataService: ProcessDataService,
        private _company: CompanyService,
        private _font: FontService,
        private _router: Router
    ) {
        this._font.addIcon(faPencil, faTrashAlt)

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);
    }

    async ngOnInit() {
        await this._auth.loadState();
        // check if the current user is an admin
        this.isAdmin = this._auth.user.role === UserRole.admin;
        const plantID = localStorage.getItem('plantId');
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));

        this._translate
            .stream([
                'processData.title',
                'processData.columns.code',
                'processData.columns.description',
                'processData.columns.valueType'
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['processData.title']);
                this._sidebar.setSelected('process_data');

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    allowFiltering: false,
                    allowEditing: false,
                    allowSorting: false,
                    alignment: 'left',
                    width: '100%'
                };

                this.columns = [{
                    ...defaultColumn,
                    dataField: 'code',
                    caption: translations['processData.columns.code'],
                    dataType: 'string'

                }, {
                    ...defaultColumn,
                    dataField: 'description',
                    caption: translations['processData.columns.description'],
                    dataType: 'string'
                }, {
                    ...defaultColumn,
                    dataField: 'valueType',
                    caption: translations['processData.columns.valueType'],
                    dataType: 'string'
                }, { // actions 
                    ...defaultColumn,
                    caption: '',
                    width: 135,
                    allowFiltering: false,
                    allowSorting: false,
                    cellTemplate: 'tplActions',
                    alignment: 'center',
                }];
            });

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });

        this._subscriptions.push(companyChangeSubscription);

        this.devices = (await this._company.getCompany(this.selectedCompanyId, true)).Devices;

        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) this.selectedDevice = device;
            } else this.selectedDevice = this.devices[0];
            this.deviceChanged(this.selectedDevice);
        }

        this.plants = await this._company.getPlants(this.selectedCompanyId);

        if (plantID) {

            const plantExists = this.plants.some((p) => p.id === Number(plantID));

            if (plantExists) {
                this.selectedPlant = this.plants.find((p) => p.id === Number(plantID));
            } else {
                this.selectedPlant = this.plants[0];
            }

            if (this.selectedDevice.plantId !== Number(plantID)) {
                // Reset selected device
                this.selectedDevice = null;
            }
        } else {
            this.selectedPlant = this.plants[0];
        }

        this.tmpDevices = this.filterDevicesByPlant(this.selectedPlant.id);


        this._buildValueTypesList();
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.processListRef) {
            const offsetTop = this.processListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.processListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async deviceChanged(device: DeviceInstance) {
        this.selectedDevice = device;

        if (this.selectedDevice === null) {
            this.processData = [];
            return;
        }

        this.isDisabled = device.DeviceModule && !device.DeviceModule.parameters;

        localStorage.setItem('deviceId', this.selectedDevice.id.toString());

        this.processData = await this._processDataService.getAll(this.selectedDevice.id);
    }

    async plantChanged(plant: PlantInstance) {
        this.selectedPlant = plant;
        localStorage.setItem('plantId', this.selectedPlant.id.toString());

        this.tmpDevices = this.filterDevicesByPlant(this.selectedPlant.id);

        if (this.tmpDevices.length > 0) {
            this.selectedDevice = this.tmpDevices[0];
        } else {
            this.selectedDevice = null;
        }

        this.deviceChanged(this.selectedDevice);
    }

    private async companyChanged(company: CompanyInstance) {
        this.selectedCompany = await this._company.getCompany(company.id, true);

        if (this.selectedCompany) {
            this.plants = this.selectedCompany?.Plants;
            this.selectedPlant = this.plants[0]; // Default Plant
            const _devices = this.filterDevicesByPlantAndCompany(this.selectedPlant.id, this.selectedCompany);
            this.tmpDevices = _devices.sort((a, b) => a.label.localeCompare(b.label)); // Sort devices by label ASC
            this.selectedDevice = this.tmpDevices[0];
            localStorage.setItem('plantId', this.selectedPlant.id.toString());
        } else {
            // Clear devices & plants if there is no company
            this.plants = [];
            this.tmpDevices = [];
        }

        this.deviceChanged(this.selectedDevice)
        localStorage.setItem('companyId', String(company.id));
    }


    createOrUpdateProcess(process_data?: ProcessDataInstance) {
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.assignedValueTypes = this.processData.map((p) => p.valueType);

        if (process_data) {
            this.selectedProcess = process_data;
        }

        this._drawer.open({
            title: process_data ? process_data.code : this._translate.instant('processData.addNew'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: process_data ? this.editProcessTemplate : this.newProcessTemplate,
            bottomBarTemplate: this.processDrawerBottomTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New Error drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }


    async saveProcessData(processData: ProcessDataInstance) {
        let res = null;
        let errorOccurred = false;

        try {
            if (processData.id) {
                res = await this._processDataService.update(processData);
                if (!res) {
                    errorOccurred = true;
                }
            } else {
                res = await this._processDataService.create(this.selectedDevice.id, processData);
                if (res) {
                    processData.id = res.id;
                } else {
                    errorOccurred = true;
                }
            }

            if (!errorOccurred) {
                const index = this.processData.some(p => p.id === processData.id); // Check whether object exists or not (returns a boolean val)
                if (index) {
                    this.processData = this.processData.map(p => p.id === processData.id ? processData : p)
                } else {
                    this.processData = [...this.processData, processData];
                }
                this._ui.showNotification(this._translate.instant('processData.saved'), 'check');
            } else {
                this._ui.showNotification(this._translate.instant('processData.error'), 'error');
            }
        } catch (error) {
            console.log(error)
            this._ui.showNotification(this._translate.instant('processData.error'), 'error');
        } finally {
            this._drawer.close();
        }

    }

    onFormSubmit() {
        const res = this.processDrawer.onSubmit();

        this.saveProcessData(res);
    }

    cloneArray(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }
    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    async deleteProcessData(processData: ProcessDataInstance) {

        if (!processData.id) {
            return;
        }

        const popupConfig: PopupConfig = {
            title: this._translate.instant('processData.dialog.title'),
            level: 'error',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('dialogs.delete.confirm'),
                },
                tertiary: {
                    text: this._translate.instant('dialogs.delete.cancel'),
                },
            },
        };

        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('processData.dialog.text', { code: processData.code })
        };

        this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
            if (result === 'primary') {
                let res = await this._processDataService.delete(processData.id);

                if (res != null) {

                    this.processData = this.processData.filter((item) => item.id !== processData.id);

                    this._ui.showNotification(this._translate.instant('processData.deleted'), 'check');
                } else {
                    //error
                    this._ui.showNotification(this._translate.instant('processData.error'), 'error');
                }
            } else {
                return
            }
        });
    }

    onCancelDrawerClicked() {
        this._drawer.close();
    }

    private filterDevicesByPlant(plantID: number) {
        return this.devices.filter((device) => device.plantId === plantID);
    }

    private _buildValueTypesList() {
        const keys = Object.keys(ValueType).filter(k => typeof ValueType[k as any] === "number");

        keys.forEach(el => {
            this.valueTypes.push({
                id: ValueType[el],
                name: ValueType[el],
                value: ValueType[el]
            });
        });

        this.valueTypes.unshift({
            id: 0,
            name: '-- none --',
            value: null
        });
    }

    private filterDevicesByPlantAndCompany(plantId: number, company: CompanyInstance) {
        if (company.Devices) {
            return company.Devices.filter((d) => d.plantId === plantId);
        }

        return []; // No Devices
    }

}
