import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FontService, NotificationConfig, NotificationService } from '@vapor/angular-ui';
import { SelectComponent } from '@vapor/angular-ui/select';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrderStatus } from '../../../models/order-device.model';
import { OrdersListService } from '../../../services/ordersList.service';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import * as moment from 'moment';
import { OrdersService } from '../../../services/orders.service';
import { OperationInstance } from '../../../models/operation.model';
import { WorksPlantsViewInstance } from '../../../models/work.model';
import { WorksService } from '../../../services/works.service';
import { faHourglassHalf, faInfoSquare } from '@fortawesome/pro-duotone-svg-icons';

import { Utility } from '../../../utils/utility';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';


@Component({
    selector: 'app-order-info',
    templateUrl: './order-info.component.html',
    styleUrls: ['./order-info.component.scss'], 
    providers: [
        NotificationService,
    ],    
})
export class OrderInfoComponent implements OnInit, OnChanges {

    @ViewChild('content', { static: false }) contentTemplate: TemplateRef<any>;
    @ViewChild('bottomBar', { static: false }) bottomBarTemplate: TemplateRef<any>;
    @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
    @ViewChild('select', { static: false }) select: TemplateRef<SelectComponent>;

    tabs = []; 
    selectedTabId: number;    
    plannedStatuses = [];
    productionStatuses = [];
    operations: OperationInstance[] = [];
    works: WorksPlantsViewInstance[] = [];
    @Input() order?: OrderListInterface;
    @Input() toBeProduced?: number;
    @Input() companyId?: number;
    private orderStatus?: OrderStatus;
    newOrderStatus?: any = 1 ;
    form: FormGroup;
    show: boolean = false;

    constructor(
        private readonly _translate: TranslateService,
        private _ordersListService: OrdersListService,
        private readonly _notification: NotificationService,
        private readonly _orderService: OrdersService,
        private readonly _works: WorksService,
        private _font: FontService,
    ) { 
        this._font.addIcon((faInfoSquare as IconDefinition), (faHourglassHalf as IconDefinition))
    }

    get submitDisabled(): boolean {
        return this.newOrderStatus === this.order?.status;
    }

    async ngOnInit(): Promise<void> {
        //TO DO: backend gives only one operation at the moment
        this.works = await this._works.getWorks(this.companyId);
        const op = (await this._orderService.getOrderOperationV2(this.order.id)).Operations;
        this.operations = op.map(o => {
            return {...o, Work: this.works.find(work => work.id == o.workId)}
        });

        this._translate.stream([
            'orders-list.status.draft',
            'orders-list.status.planned',
            'orders-list.status.running',
            'orders-list.status.suspended',
            'orders-list.status.completed',
            'orders-list.orderTitle',
            'orders.createEdit.workcycle'            
        ]).subscribe((translations) => {
            this.tabs = [{
                id: 1,
                text: translations['orders-list.orderTitle'],
                disabled: false,
            }, {
                id: 2,
                text: translations['orders.createEdit.workcycle'],
                disabled: false,
            }];
            this.selectedTabId = 1;

            this.plannedStatuses = [
                { id: OrderStatus.draft, val: translations['orders-list.status.draft'] },
                { id: OrderStatus.planned, val: translations['orders-list.status.planned'] }
            ];
            this.productionStatuses = [
                { id: OrderStatus.running, val: translations['orders-list.status.running'], disabled: this.order.status != OrderStatus.running },
                { id: OrderStatus.suspended, val: translations['orders-list.status.suspended'], disabled: this.order.status == OrderStatus.running },
                { id: OrderStatus.completed, val: translations['orders-list.status.completed'], disabled: this.order.status == OrderStatus.running },
            ];
        })   
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order?.currentValue?.status) {
            const currentValue = changes.order.currentValue;
            this.newOrderStatus = currentValue.status;
            if (this.productionStatuses.length == 3) {
                this.productionStatuses[0].disabled = currentValue.status != OrderStatus.running;
                this.productionStatuses[1].disabled = currentValue.status == OrderStatus.running;
                this.productionStatuses[2].disabled = currentValue.status === OrderStatus.running;
            }
        }
    }

    async editClicked() {
        const result = await this._ordersListService.editOrderStatus(this.order.id, this.newOrderStatus);
        if(String(result).charAt(0) !== '2'){
            this._translate.stream([
                'orders-list.orderInfo.editOrderStatusError',
            ], { orderCode: this.order.code}).subscribe((translations) => {
                const config: NotificationConfig = {
                    content: translations['orders-list.orderInfo.editOrderStatusError'],
                    type: 'toast',
                    style: 'error', 
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            });
        } else {
            this._translate.stream([
                'orders-list.orderInfo.editOrderStatusMessage',
            ], { orderCode: this.order.code}).subscribe((translations) => {
                const config: NotificationConfig = {
                    content: translations['orders-list.orderInfo.editOrderStatusMessage'],
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
                this.order.status = this.newOrderStatus;
            });
        }
    }

    plannedStatusChanged(status: any): void{
        this.newOrderStatus = status.id;
    }
    
    productionStatusChanged(status: any): void{
        if(status.disabled) return;
        if(this.order.status == 3) return;
        this.newOrderStatus = status.id;
    }

    toJSON(data: any){
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error(error)
        }
    }
    
    translate = (label: string) => {
        return this._translate.instant(label);
    } 

    hasJsonInfo(): boolean {
        return !!this.order?.jsonInfo && Object.keys(this.toJSON(this.order.jsonInfo)).length > 0;
    }

    parseDate(date: any): string {
        const local = localStorage.getItem('lang') || 'en';
        return local === 'en' ? moment(date).format('MM/DD/YYYY') : moment(date).format('DD/MM/YYYY');
    }
    
    getInterval(milliseconds: number): string {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
    
        const pad = (num: number) => num < 10 ? '0' + num : num.toString();
        const formattedHours = pad(hours);
        const formattedMinutes = pad(minutes);
        const formattedSeconds = pad(seconds);
    
        return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
    }

    formatDecimal(value: number, decimals: number): string {
        return Utility.formatItDecimal(value, decimals);
    }
}
