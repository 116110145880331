import { OnInit, Component, ViewChild, TemplateRef, ElementRef, AfterContentChecked, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Drawer, FontService, NotificationConfig, NotificationService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupResult, PopupService } from '@vapor/angular-ui';
import { ColumnFilterOperation, ColumnFilterTargetValues, TsTreeListColumn, TsTreeListRowDraggingOnDragChange, TsTreeListRowDraggingOnReorder } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faWindowMaximize, faPencil, faTrashAlt, faPlus, faLayerGroup, faLayerPlus, faLayerMinus, faSortNumericUpAlt } from '@fortawesome/pro-regular-svg-icons';
import { cloneDeep, isEqual } from 'lodash'
import * as moment from 'moment';

import { OrdersGroupsViewInstance } from '../../../models/orders-groups-view.model';
import { OrderStatus } from '../../../models/order-device.model';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { OrdersListService } from '../../../services/ordersList.service';
import { DeviceInstance } from '../../../models/device.model';
import { OrderInfoComponent } from '../../ui/order-info/order-info.component';
import { OrderCoreInstance } from '../../../models/order-core.model';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import { OrdersCoreService } from '../../../services/ordersCore.service';
import { OrderDrawerComponent } from '../../ui/order-drawer/order-drawer.component';
import { OrderGroupsInstance } from '../../../models/order-group.model';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { PlantInstance } from '../../../models/plant.model';
import { OrderGroupInfoComponent } from '../../ui/order-group-info/order-group-info.component';
 
import { TreeListComponent } from '@vapor/angular-ui-extra';
import { OrdersGroupEditorComponent } from '../../ui/orders-group-editor/orders-group-editor.component';
import { AddOrdersToGroupComponent } from '../../ui/add-orders-to-group/add-orders-to-group.component';
import { Subscription } from 'rxjs';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { Utility } from '../../../utils/utility';

interface OrderListInterfaceUi extends OrderListInterface {
    targetUi?: string;
}

@Component({
    selector: 'app-orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss'],
    providers: [
        NotificationService,
    ],  
    encapsulation: ViewEncapsulation.None
})
export class OrdersListComponent implements OnInit, AfterContentChecked, OnDestroy {
    @ViewChild('plannedOrdersList', { static: false, read: ElementRef }) plannedOrdersListRef!: ElementRef;
    @ViewChild('productionOrdersList', { static: false, read: ElementRef }) productionOrdersListRef!: ElementRef;
    @ViewChild('newOrderTemplate', { static: false }) newOrderTemplate: TemplateRef<any>;
    @ViewChild('newOrderBottomBarTemplate', { static: false }) newOrderBottomBarTemplate: TemplateRef<any>;
    @ViewChild('newOrder', { static: false }) newOrder: OrderDrawerComponent;
    @ViewChild('orderInfoTemplate', { static: false }) orderInfoTemplate: TemplateRef<any>;
    @ViewChild('orderInfoBottomBarTemplate', { static: false }) orderInfoBottomBarTemplate: TemplateRef<any>;
    @ViewChild('orderInfo', { static: false }) orderInfoComponent: OrderInfoComponent;
    @ViewChild('editOrderTemplate', { static: false }) editOrderTemplate: TemplateRef<any>;
    @ViewChild('editOrderBottomBarTemplate', { static: false }) editOrderBottomBarTemplate: TemplateRef<any>;
    @ViewChild('editOrder', { static: false }) editOrder: OrderDrawerComponent;
    @ViewChild('plannedOrdersList', { static: false }) plannedOrdersList!: TreeListComponent;
    @ViewChild('productionOrdersList', { static: false }) productionOrdersList!: TreeListComponent;
    @ViewChild('newOrdersGroupTemplate', { static: false }) newOrdersGroupTemplate: TemplateRef<any>;
    @ViewChild('newOrdersGroup', { static: false }) newOrdersGroup: OrdersGroupEditorComponent;
    @ViewChild('newOrdersGroupBottomBarTemplate', { static: false }) newOrdersGroupBottomBarTemplate: TemplateRef<any>;
    @ViewChild('editOrdersGroupTemplate', { static: false }) editOrdersGroupTemplate: TemplateRef<any>;
    @ViewChild('editOrdersGroup', { static: false }) editOrdersGroup: OrdersGroupEditorComponent;
    @ViewChild('editOrdersGroupBottomBarTemplate', { static: false }) editOrdersGroupBottomBarTemplate: TemplateRef<any>;
    @ViewChild('addOrdersToGroupTemplate', { static: false }) addOrdersToGroupTemplate: TemplateRef<any>;
    @ViewChild('addOrdersToGroup', { static: false }) addOrdersToGroup: AddOrdersToGroupComponent;
    @ViewChild('addOrdersToGroupBottomBarTemplate', { static: false }) addOrdersToGroupBottomBarTemplate: TemplateRef<any>;
    @ViewChild('orderGroupInfoTemplate', { static: false }) orderGroupInfoTemplate: TemplateRef<any>;
    @ViewChild('orderGroupInfoBottomBarTemplate', { static: false }) orderGroupInfoBottomBarTemplate: TemplateRef<any>;
    @ViewChild('orderGroupInfo', { static: false }) orderGroupInfoComponent: OrderGroupInfoComponent;
    @ViewChild('newOrderOptions') newOrderOptions: ElementRef;

    tabs = [];
    selectedTabId: number;

    selectedCompanyId: number;
    selectedPlantId: number;

    company: CompanyInstance;
    plants: PlantInstance[] = [];
    selectedPlant: PlantInstance;
    plantValue: string;

    fromDate: Date;
    toDate: Date;

    plannedStatuses = [];
    productionStatuses = [];

    plannedOrdersColumns: TsTreeListColumn[] = [];
    productionOrdersColumns: TsTreeListColumn[] = [];

    plannedOrders: OrderListInterfaceUi[] = [];
    productionOrders: OrderListInterfaceUi[] = [];
    showTableSpinner = false;

    selectedOrder?: OrderListInterface;
    selectedOrderGroup?: OrderGroupsInstance;

    plannedOrdersListHeight = '100vh';
    productionOrdersListHeight = '100vh';

    plannedOrdersSelectedDevicesId?: number[] = [];
    plannedOrdersGroups: OrderListInterface[] = [];

    showNewOrderOptions = false;
    lastShowNewOrderOptions = false;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _drawer: Drawer,
        private _translate: TranslateService,
        private _font: FontService,
        private _spinner: OverlaySpinnerService,
        private _ordersListService: OrdersListService,
        public _company: CompanyService,
        private _ordersCoreService: OrdersCoreService,
        private readonly _notification: NotificationService,
        private readonly _popup: PopupService,
        public _auth: AuthService,
        private readonly _router: Router,
        private readonly _renderer: Renderer2
    ) {

        this._font.addIcon(faWindowMaximize, faPencil, faTrashAlt, faPlus,
            faLayerGroup, faLayerPlus, faLayerMinus, faSortNumericUpAlt);

        // Collapse drawer when the user navigates to another page
        const routerEventSubscription =
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this._drawer.collapse();
                }
            });
        this._subscriptions.push(routerEventSubscription);

        // Close tooltips when clicking away
        this._renderer.listen('window', 'click', this.tooltipClickHandler);
        this._renderer.listen('window', 'contextmenu', this.tooltipClickHandler);
    }

    get newGroupEnabled(): boolean {
        // At least one ungrouped order must be selected
        return this.plannedOrdersSelected.filter((order: OrderListInterface) => !order.isGroup && !order.groupId).length > 0;
    }

    get addToGroupEnabled(): boolean {
        return this.newGroupEnabled;
    }

    get detachFromGroupEnabled(): boolean {
        // At least one order in a group must be selected
        return this.plannedOrdersSelected.filter((order: OrderListInterface) => order.groupId).length > 0;
    }

    get plannedOrdersSelected(): OrderListInterface[] {
        return this.plannedOrders
            .filter(order => order.selected)
            .sort((first, second) => first.rank - second.rank);
    }

    async ngOnInit(): Promise<void> {
        await this._auth.loadState();
        this._spinner.show({ type: "spinner" });
        this._translate.stream([
            'orders-list.title',
            'orders-list.todo',
            'orders-list.data',
            'orders-list.orders',
            'orders-list.inprogress-done',
            'orders-list.status.draft',
            'orders-list.status.planned',
            'orders-list.status.running',
            'orders-list.status.suspended',
            'orders-list.status.completed',
            'orders-list.search',
            'orders-list.columns.sort',
            'orders-list.columns.code',
            'orders-list.columns.target',
            'orders-list.columns.product-description',
            'orders-list.columns.date',
            'orders-list.columns.devices',
            'orders-list.columns.status'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['orders-list.title']);

            this._sidebar.setSelected('orders-list');

            this.tabs = [{
                id: 1,
                text: translations['orders-list.todo'],
                disabled: false,
            }, {
                id: 2,
                text: translations['orders-list.inprogress-done'],
                disabled: false,
            }];

            this.selectedTabId = 1;
            this.toDate = localStorage.getItem('toDate') ? new Date(localStorage.getItem('toDate')) : new Date();
            this.fromDate = localStorage.getItem('fromDate') ? new Date(localStorage.getItem('fromDate')) : new Date(new Date().setMonth(new Date().getMonth() - 6));

            localStorage.setItem('toDate', this.toDate.toISOString());
            localStorage.setItem('fromDate', this.fromDate.toISOString());

            this.plannedStatuses = [
                {
                    id: OrderStatus.draft,
                    label: translations['orders-list.status.draft'],
                },
                {
                    id: OrderStatus.planned,
                    label: translations['orders-list.status.planned'],
                },
            ];
            this.productionStatuses = [
                {
                    id: OrderStatus.running,
                    label: translations['orders-list.status.running'],
                },
                {
                    id: OrderStatus.suspended,
                    label: translations['orders-list.status.suspended'],
                },
                {
                    id: OrderStatus.completed,
                    label: translations['orders-list.status.completed'],
                },
            ];

            // Documentation on calculateFilterExpression:
            // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/columns/#calculateFilterExpression
            const defaultColumn: TsTreeListColumn = {
                dataField: '',
                headerPlaceholder: translations['orders-list.search'],
                allowFiltering: true,
                allowEditing: false,
                allowSorting: false,
            };
            this.plannedOrdersColumns =
                [
                    { // id
                        caption: 'id',
                        dataField: 'id',
                        showColumn: false,
                    },
                    {
                      // rank
                      ...defaultColumn,
                      dataField: 'rank',
                      caption: translations['orders-list.columns.sort'],
                      dataType: 'string',
                      allowFiltering: false,
                      cellTemplate: 'tplRank',
                      width: 80,
                    },
                    {
                        // selection
                        ...defaultColumn,
                        allowFiltering: false,
                        cellTemplate: 'tplSelection',
                        width: 80,
                    },
                    { // code
                        ...defaultColumn,
                        caption: translations['orders-list.columns.code'],
                        dataField: 'lkpCode',
                        dataType: 'string',
                        cellTemplate: 'tplCode',
                        width: 150,
                    },
                    { // target
                        ...defaultColumn,
                        caption: translations['orders-list.columns.target'],
                        dataField: 'targetUi',
                        dataType: 'string',
                        alignment: 'right',
                        width: 180,
                    },
                    { // product
                        ...defaultColumn,
                        caption: translations['orders-list.columns.product-description'],
                        dataField: 'productLabel',
                        dataType: 'string'
                    },
                    { // date
                        ...defaultColumn,
                        caption: translations['orders-list.columns.date'],
                        dataField: 'date',
                        dataType: 'date',
                        format: 'shortDate',
                        width: 135,
                    },
                    { // devices
                        ...defaultColumn,
                        caption: translations['orders-list.columns.devices'],
                        dataField: 'deviceLabels',
                        dataType: 'object',
                        width: 200,
                        cellTemplate: 'tplDevices',
                        calculateFilterExpression: this._plannedOrdersDevicesFilter,
                    },
                    { // status
                        ...defaultColumn,
                        caption: translations['orders-list.columns.status'],
                        dataField: 'status',
                        dataType: 'object',
                        cellTemplate: 'tplStatus',
                        width: 120,
                        lkpDataSource: this.plannedStatuses,
                        lkpKeyField: 'id',
                        lkpResultField: 'label'
                    },
                    { // actions
                        ...defaultColumn,
                        caption: '',
                        alignment: 'center',
                        width: 135,
                        allowFiltering: false,
                        allowResizing: false,
                        allowFixing: false,
                        cellTemplate: 'tplActions',
                    },
                ];
        });

        this.setupDrawer();

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                this.companyChanged(company);
            });
        this._subscriptions.push(companyChangeSubscription);

        this.selectedCompanyId = parseInt(localStorage.getItem('companyId'));
        this.selectedPlantId = parseInt(localStorage.getItem('plantId'));

        try {
            this.company = await this._company.getCompany(this.selectedCompanyId);
            this.plants = this.company.Plants.map(plant => {
                return {
                    ...plant,
                    val: plant.description
                }
            });
            if (!this.selectedPlantId){
                await this.handlePlantChange(this.plants[0], false)
            } else {
                await this.loadData(false);
            }
            this.productionOrdersColumns = cloneDeep(this.plannedOrdersColumns);
            // Remove selection column
            this.productionOrdersColumns.splice(this.productionOrdersColumns.findIndex(c => c.cellTemplate === 'tplSelection'), 1);
            // remove rank column
            this.productionOrdersColumns.splice(this.productionOrdersColumns.findIndex(c => c.dataField === "rank"), 1);
            this.productionOrdersColumns.forEach((value: TsTreeListColumn, index: number) => {
                if (value.dataField === 'status') {
                    value.lkpDataSource = this.productionStatuses;
                }
                // if dataField is not id and is not the last column (actions column) allow sorting
                if (value.dataField !== 'id' && index !== this.productionOrdersColumns.length - 1) {
                    value.allowSorting = true;
                }
            });
        } catch(err) {
            this.catchError('Error while initiliaze table');
        } finally {
            this._spinner.removeOverlay();
        }
    
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.plannedOrdersListRef) {
            const offsetTop = this.plannedOrdersListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.plannedOrdersListHeight = `calc(100vh - ${offsetTop}px - 32px)`;
        }
        if (this.productionOrdersListRef) {
            const offsetTop = this.productionOrdersListRef?.nativeElement?.getBoundingClientRect()?.top + 16 || 0;
            this.productionOrdersListHeight = `calc(100vh - ${offsetTop}px - 32px)`;
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    private async loadData(showSpinner: boolean) {
        if (showSpinner) this._spinner.show({ type: "spinner" });
        try {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId) || this.plants[0];
            this.selectedPlantId = this.selectedPlant.id;
            if (!this.selectedPlant) {
                throw new Error();
            }
            this.plantValue = this.selectedPlant.description;
            if (this.selectedTabId === 1) {
                this.plannedOrders = await this.fetchOrders(false);
                this.filterPlannedOrdersGroups();
            } else {
                this.productionOrders = await this.fetchOrders(true);
            }
        } catch(err) {
            this.catchError(err);
        }finally {
            if (showSpinner) this._spinner.removeOverlay(); 
        }
        
    }

    private filterPlannedOrdersGroups() {
        // If no row is selected we cannot determine which groups to show
        if (!this.plannedOrdersSelected.length) {
            this.plannedOrdersGroups = [];
            return;
        }

        // Find the orders with the same devices of the selected orders
        const children: OrderListInterface[] =
            this.plannedOrders.filter((order: OrderListInterface) => {
                const devicesId: Set<number> = new Set(order.Devices?.map((device: DeviceInstance) => device.id).sort());
                const selectedDevicesId: Set<number> = new Set(this.plannedOrdersSelectedDevicesId?.sort());
                return (!order.isGroup && order.groupId && isEqual(devicesId, selectedDevicesId));
            });

        // Find their group ids
        const uniqueGroupIds: Set<number> =
            new Set(children.map((order: OrderListInterface) => order.groupId));

        // Find the order groups with children with the same devices of the selected orders and empty groups
        const parents: OrderListInterface[] =
            this.plannedOrders.filter((order: OrderListInterface) => {
                return order.isGroup && (!order.Orders?.length || uniqueGroupIds.has(order.id));
            });

        // This is a subset of `plannedOrders` that contains only groups and their children
        // with the same devices of the selected orders
        this.plannedOrdersGroups = [...children, ...parents];
    }

    async handlePlantChange(plant: PlantInstance, showSpinner: boolean): Promise<void> {
        this.selectedPlantId = plant.id;
        localStorage.setItem('plantId', this.selectedPlantId.toString());
        await this.loadData(showSpinner);
    }

    async fetchOrders(inProduction: boolean): Promise<OrderListInterface[]> {
        const dateFrom = inProduction ? this.fromDate : null;
        const dateTo = inProduction ? this.toDate : null;
        const retrievedOrders: OrdersGroupsViewInstance[] = await this._ordersListService.fetchAll(this.selectedPlantId, inProduction, dateFrom, dateTo) || [];
        // Aggregate orders and groups and process data to accomodate the tree list
        let orders: OrderListInterfaceUi[] = [];
        retrievedOrders.forEach((order: OrdersGroupsViewInstance) => {
            if (order.Order) {
                const deviceLabels = order.Order.Devices?.map((device: DeviceInstance) => device.label);
                orders.push({
                    ...order.Order,
                    targetUi: Utility.formatItDecimal(order?.Order?.target, order.Order?.Product?.ProductionUnit?.decimal || 0) + ' ' + (order.Order?.Product?.ProductionUnit?.unit || ''),
                    targetChangeOver: order.Order?.Devices[0]?.OrdersDevices?.priorityErrorTarget,
                    isGroup: false,
                    deviceLabels: deviceLabels,
                    date: order.Order.deliveryDate,
                    productLabel: order.Order.Product.code + ' - ' + order.Order.Product.name + (order.Order?.Variation1?.code ? ('  ' + order.Order.Variation1.code + ' - ' + order.Order.Variation1.description) : ''),
                    rank: order.rank || null,
                    lkpCode: order.Order.code,
                    selected: false,
                    checkBoxEnabled: true,
                });
            } else if (order.OrderGroup) {
                orders.push({
                    ...order.OrderGroup,
                    targetUi: Utility.formatItDecimal(order?.OrderGroup?.target,order.OrderGroup?.Orders[0]?.Product?.ProductionUnit?.decimal || 0) + ' ' + ( order?.OrderGroup?.Orders[0]?.Product?.ProductionUnit?.unit || ''), 
                    isGroup: true,
                    rank: order.rank || null,
                    groupId: null,
                    lkpCode: order.code,
                    selected: false,
                    checkBoxEnabled: false,
                });
                order.OrderGroup?.Orders.forEach((o: OrderCoreInstance, rank: number) => {
                    const deviceLabels = o.Devices?.map((device: DeviceInstance) => device.label);
                    orders.push({
                        ...o,
                        targetChangeOver: order.Order?.Devices[0]?.OrdersDevices?.priorityErrorTarget,
                        isGroup: false,
                        deviceLabels: deviceLabels,
                        date: o.deliveryDate,
                        productLabel: o.Product.code + ' - ' + o.Product.name + (order.Order?.Variation1?.code ? ('  ' + order.Order.Variation1.code + ' - ' + order.Order.Variation1.description) : ''),
                        rank: rank + 1,
                        lkpCode: order.OrderGroup.code + o.code, // orderGroup code + order code
                        selected: false,
                        checkBoxEnabled: true,
                    });
                });
            }
        });

        return orders;
    }

    async companyChanged(company: CompanyInstance): Promise<void> {
        this.company = company;
        this.selectedTabId = 1;
        this.plannedOrders = [];
        this.productionOrders = [];
        this.plants = this.company.Plants.map(plant => {
            return {
                ...plant,
                val: plant.description
            }
        });
        await this.handlePlantChange(this.plants[0], true);
    }

    async selectedTabChange(index: number) {
        // Collapse the drawer everytime a tab is selected
        this._drawer.collapse();

        // In order to avoid slowing down the page, we load the production orders
        // only when needed, instead of doing it when the page is created
        if (index === 2 && this.productionOrders.length == 0) {
            this._spinner.show({ type: "spinner" });
            this.productionOrders = await this.fetchOrders(true);
            this._spinner.removeOverlay();
        }
    }

    onSortByDateClicked() {
        const popupConfig: PopupConfig = {
            title: this._translate.instant('orders-list.sort-date.title'),
            level: 'info',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('orders-list.confirm-button'),
                },
                secondary: {
                    text: this._translate.instant('orders-list.cancel-button'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: this._translate.instant('orders-list.sort-date.message'),
        };
        this._popup.show(popupConfig, bodyConfig).subscribe(async (button: PopupResult) => {
            if (button == 'primary') {
                try {
                    this._spinner.show({ type: "spinner" });
                    await this._ordersCoreService.sortByDate(this.selectedPlantId);
                    await this.loadData(false);
                } catch (err) {
                    this.catchError(err);
                } finally {
                    this._spinner.removeOverlay();
                }
            }
        });
    }

    onNewOrderDevicesClicked() {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.new-order'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.newOrderTemplate,
            bottomBarTemplate: this.newOrderBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            this._drawer.expand();
        });
    }

    async onNewOrderWorkcyclesClicked() {
        await this._router.navigate(['orders', 'new']);
    }

    async onCreateOrderClicked() {
        try {
            if (!this.newOrder.formValid) {
                return;
            }
    
            this._spinner.show({ type: "spinner" });
    
            const result = await this.newOrder.create();
            if (result) {
                if (typeof result === 'number') {
                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.error-save'),
                        type: 'toast',
                        style: 'error',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);
                } else {
                    this._drawer.collapse();

                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.saved'),
                        type: 'toast',
                        style: 'check',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);
        
                    // Reload the planned orders list when a new order is created
                    await this.loadData(false);
                }
            }
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async onEditOrderStatusClicked() {
        if (this.orderInfoComponent) {
            try {
                this._spinner.show({ type: "spinner" });
                await this.orderInfoComponent.editClicked();
                await this.loadData(false);
                this._drawer.collapse();
            } catch (err) {
                this.catchError(err);
            } finally {
                this._spinner.removeOverlay();
            }
        } else {
            console.warn('Order info component unavailable');
        }
    }

    async onEditOrderGroupStatusClicked() {
        if (this.orderGroupInfoComponent) {
            try {
                this._spinner.show({ type: "spinner" });
                await this.orderGroupInfoComponent.editClicked();
                await this.loadData(false);
                this._drawer.collapse();
            } catch (err) {
                this.catchError(err)
            } finally {
                this._spinner.removeOverlay();
            }
        } else {
            console.warn('Order Group info component unavailable');
        }
    }

    async onEditOrderButtonClicked() {
        try {
            if (!this.editOrder.formValid) {
                return;
            }
    
            this._spinner.show({ type: "spinner" });
    
            const result = await this.editOrder.update();
            if (typeof result != 'undefined') {
                if (typeof result === 'number') {
                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.error-save'),
                        type: 'toast',
                        style: 'error',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);
                } else {
                    this._drawer.collapse();

                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.saved'),
                        type: 'toast',
                        style: 'check',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);
        
                    // Reload the planned orders list when a new order is created
                    await this.loadData(false);
                }
            }
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    onCancelDrawerClicked() {
        this._drawer.collapse();
    }

    private setupDrawer() {
        const toggleSubscription =
            this._drawer.toggle$.subscribe((toggleValue: boolean) => {
                // Close drawers when they are collapsed, in order to clean up the resources
                if (!toggleValue) {
                    // Wait for the collapse animation
                    setTimeout(() => {
                        // Close the drawer
                        this._drawer.close();

                        // Reset the status
                        this.selectedOrder = null;
                        this.selectedOrderGroup = null;
                        this.newOrder?.reset();
                        this.editOrder?.reset();
                        this.newOrdersGroup?.reset();
                        this.editOrdersGroup?.reset();
                        this.addOrdersToGroup?.reset();
                    }, 550);
                }
            });
        this._subscriptions.push(toggleSubscription);

        const closeSubscription = this._drawer.close$.subscribe((drawerInfo: DrawerInstanceInfo) => {});
        this._subscriptions.push(closeSubscription);
    }

    onOrderInfoClicked(order: OrderListInterface) {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }
        this.selectedOrder = order;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders.createEdit.orderInfo') + ' - ' + order.code,
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: 700,
            contentTemplate: this.orderInfoTemplate,
            bottomBarTemplate: this.orderInfoBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Order info drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onOrdersGroupInfoClicked(orderGroup: OrderGroupsInstance) {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.selectedOrderGroup = orderGroup;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.groupTitle'),
            subTitle: orderGroup.code,
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.orderGroupInfoTemplate,
            bottomBarTemplate: this.orderGroupInfoBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Orders group info drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }
    
    onEditOrderClicked(order: OrderListInterface) {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (order.orderWithCycle) {
            this._router.navigate(['orders', 'edit', order.id]);
            return;
        }

        this.selectedOrder = order;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.edit-order'),
            subTitle: order.code,
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.editOrderTemplate,
            bottomBarTemplate: this.editOrderBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Edit order drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onEditOrdersGroupClicked(order: OrderListInterface) {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.selectedOrder = order;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.edit-group'),
            subTitle: order.code,
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.editOrdersGroupTemplate,
            bottomBarTemplate: this.editOrdersGroupBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Edit orders group drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    removeOrder(orderId: number) {
        if (this.selectedTabId == 1) {
            this.plannedOrders = this.plannedOrders.filter(order => order.id != orderId);
        } else if (this.selectedTabId == 2) {
            this.productionOrders = this.plannedOrders.filter(order => order.id != orderId)
        }
    }

    onDeleteOrdersGroupClicked(orderGroup: OrderGroupsInstance){
        try {            
            this._translate.stream([
                'orders-list.delete.groupTitle',
                'orders-list.delete.groupMessage',
                'orders-list.delete.deleteButton',
                'orders-list.delete.cancelButton',
                'orders-list.delete.deleteAlsoOrdersButton',
                'orders-list.delete.deleteOnlyGroupButton'
            ], { code: orderGroup.code}).subscribe((translations) => {
                const popupConfig: PopupConfig = {
                    title: translations['orders-list.delete.groupTitle'],
                    level: 'error',
                    showCloseButton: true,
                    visible: true,
                    dragEnabled: false,
                    bottomButtons: {
                        primary: {
                            text: translations['orders-list.delete.deleteAlsoOrdersButton'],
                        },
                        secondary: {
                            text: translations['orders-list.delete.deleteOnlyGroupButton'],
                        },
                        tertiary: {
                            text: translations['orders-list.delete.cancelButton'],
                        },
                    },
                };
                const bodyConfig: PopupBodyConfig = {
                    content: translations['orders-list.delete.groupMessage'],
                };
                
                this._popup.show(popupConfig, bodyConfig).subscribe(async (btn) => {
                    try {
                        if(btn=='primary' || btn=='secondary'){
                            this._spinner.show({ type: "spinner" });
                            const deleteOrders = btn == 'primary';
                            const deleteResult = await this._ordersListService.deleteOrderGroup(orderGroup.id, deleteOrders);
                            if (String(deleteResult).charAt(0) !== '2') {
                                this._translate.stream([
                                    'orders-list.delete.groupErrorMessage'
                                ], { code: orderGroup.code, type: 'order' }).subscribe((translations)=>{
                                    const config: NotificationConfig = {
                                        content: translations['orders-list.delete.groupErrorMessage'],
                                        type: 'toast',
                                        style: 'error',
                                        timeout: 5000,
                                        position: 'right',
                                    };
                                    this._notification.show(config);
                                    return;
                                })
                            } else {
                                this._translate.stream([
                                    'orders-list.delete.groupSuccessMessage'
                                ], { code: orderGroup.code, type: 'order' }).subscribe(async (translations) => {
                                    try {
                                        await this.loadData(false);
                                        const config: NotificationConfig = {
                                            content: translations['orders-list.delete.groupSuccessMessage'],
                                            type: 'toast',
                                            style: 'check',
                                            timeout: 5000,
                                            position: 'right',
                                        };
                                        this._notification.show(config);                                
                                    } catch (error) {
                                        throw error;
                                    }
                                });
                            }                     
                        }
                    } catch (error) {
                        console.error(error);
                        this.catchError("Error during data reloading");     
                    } finally {
                        this._spinner.removeOverlay();
                    }
                })
            });        
        } catch (error) {
            this.catchError(error);
        }
    }
    

    onDeleteOrderClicked(order: OrderCoreInstance){
        try {
            this._translate.stream([
                'orders-list.delete.orderTitle',
                'orders-list.delete.orderMessage',
                'orders-list.delete.orderSuccessMessage',
                'orders-list.delete.deleteButton',
                'orders-list.delete.cancelButton',
            ], { code: order.code, type: 'order' }).subscribe((translations) => {
                const popupConfig: PopupConfig = {
                    title: translations['orders-list.delete.orderTitle'],
                    level: 'error',
                    showCloseButton: true,
                    visible: true,
                    dragEnabled: false,
                    bottomButtons: {
                        primary: {
                            text: translations['orders-list.delete.deleteButton'],
                        },
                        secondary: {
                            text: translations['orders-list.delete.cancelButton'],
                        },
                    },
                };
                const bodyConfig: PopupBodyConfig = {
                    content: translations['orders-list.delete.orderMessage'],
                };
                
                this._popup.show(popupConfig, bodyConfig).subscribe(async (btn) => {
                    try{                    
                        if(btn=='primary'){
                            //CALL DELETE API
                            this._spinner.show({ type: "spinner" });
                            const deleteResult = await this._ordersCoreService.delete(order.id);
                            if (String(deleteResult).charAt(0) !== '2') {
                                this._translate.stream([
                                    'orders-list.delete.orderErrorMessage'
                                ], { code: order.code}).subscribe((translations)=>{
                                    const config: NotificationConfig = {
                                        content: translations['orders-list.delete.orderErrorMessage'],
                                        type: 'toast',
                                        style: 'error',
                                        timeout: 5000,
                                        position: 'right',
                                    };
                                    this._notification.show(config);
                                    return;
                                })
                            } else {
                                this._translate.stream([
                                    'orders-list.delete.orderSuccessMessage'
                                ], { code: order.code}).subscribe(async (translations)=>{
                                        await this.loadData(false);
                                        const config: NotificationConfig = {
                                            content: translations['orders-list.delete.orderSuccessMessage'],
                                            type: 'toast',
                                            style: 'check',
                                            timeout: 5000,
                                            position: 'right',
                                        };
                                        this._notification.show(config);
                                })
                            }                    
                        }
                } catch (error) {
                    this.catchError("Error during data reloading");   
                } finally {
                    this._spinner.removeOverlay();
                }
            })
                
        });        
        } catch (error) {
            this.catchError(error);
        }
    }    

    async handleDateChange(date: Date, toggle: string) {
        switch (toggle) {
            case 'from':
                this.fromDate = date;
                localStorage.setItem('fromDate', this.fromDate.toISOString());
                break;
            case 'to':
                this.toDate = date;
                localStorage.setItem('toDate', this.toDate.toISOString());
                break;
            default:
                break;
        }
        this._spinner.show({ type: "spinner" });

        this.productionOrders = await this.fetchOrders(this.selectedTabId === 2);
        this._spinner.removeOverlay();
    }

    onRowSelected(order: OrderListInterface) {
        // Keep track of the devices selected
        if (!this.plannedOrdersSelectedDevicesId.length)
            this.plannedOrdersSelectedDevicesId = order.Devices?.map(device => device.id);

        this.filterPlannedOrdersGroups();

        // Update tree list selection to render the rows as selected
        const ids = this.plannedOrdersSelected.map((order: OrderListInterface) => order.id);
        this.plannedOrdersList.selectRows(ids, false);

        // Update checkbox state
        this.updateCheckBoxState();
    }

    onRowUnselected(order: OrderListInterface) {
        // Unselect the devices when no order is selected
        if (this.plannedOrdersSelected.length == 0)
            this.plannedOrdersSelectedDevicesId = [];

        this.filterPlannedOrdersGroups();

        // Update tree list selection to render the rows as selected
        const ids = this.plannedOrdersSelected.map((order: OrderListInterface) => order.id);
        this.plannedOrdersList.selectRows(ids, false);

        // Update checkbox state
        this.updateCheckBoxState();
    }

    onNewGroupClicked() {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.new-group'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.newOrdersGroupTemplate,
            bottomBarTemplate: this.newOrdersGroupBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New orders group drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onAddToGroupClicked() {
        // Make sure the previous drawer is closed
        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('orders-list.group-association.title'),
            subTitle: '',
            expanded: false,
            marginTop: 48,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: 1024,
            contentTemplate: this.addOrdersToGroupTemplate,
            bottomBarTemplate: this.addOrdersToGroupBottomBarTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`Add to group drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    onDetachFromGroupClicked() {
        // Sanity check
        if (this.plannedOrdersSelected?.length == 0) {
            this.catchError('At least one grouped order must be selected');
            return;
        }

        // List of order codes
        const orderCodes: string[] = this.plannedOrdersSelected.map((order: OrderListInterface) => order.code);
        const firstOrder: OrderListInterface = this.plannedOrdersSelected[0];
        const group: OrderListInterface = this.plannedOrders.find((order: OrderListInterface) => order.isGroup && order.id === firstOrder.groupId);
        const translateArgs = { code: firstOrder.code, codes: orderCodes.join(', '), groupCode: group.code };

        const titleString = orderCodes.length == 1
            ? this._translate.instant('orders-list.group-disassociation.title', translateArgs)
            : this._translate.instant('orders-list.group-disassociation.title_plural', translateArgs);
        const bodyString = orderCodes.length == 1
            ? this._translate.instant('orders-list.group-disassociation.message', translateArgs)
            : this._translate.instant('orders-list.group-disassociation.message_plural', translateArgs);
        const successString = orderCodes.length == 1
            ? this._translate.instant('orders-list.group-disassociation.success', translateArgs)
            : this._translate.instant('orders-list.group-disassociation.success_plural', translateArgs);
        const failureString = orderCodes.length == 1
            ? this._translate.instant('orders-list.group-disassociation.failure', translateArgs)
            : this._translate.instant('orders-list.group-disassociation.failure_plural', translateArgs);

        const popupConfig: PopupConfig = {
            title: titleString,
            level: 'info',
            showCloseButton: true,
            visible: true,
            dragEnabled: false,
            bottomButtons: {
                primary: {
                    text: this._translate.instant('orders-list.confirm-button'),
                },
                secondary: {
                    text: this._translate.instant('orders-list.cancel-button'),
                },
            },
        };
        const bodyConfig: PopupBodyConfig = {
            content: bodyString,
        };
        this._popup.show(popupConfig, bodyConfig).subscribe(async (button: PopupResult) => {
            if (button == 'primary') {
                try {
                    this._spinner.show({ type: "spinner" });

                    const orderIds: number[] = this.plannedOrdersSelected.map((order: OrderListInterface) => order.id);
                    const result = await this._ordersListService.disassociation(orderIds);
                    if (result && result["status"] >= 200 && result["status"] <= 299) {
                        // Refresh list
                        await this.loadData(false);

                        // Notification
                        const config: NotificationConfig = {
                            content: successString,
                            type: 'toast',
                            style: 'check',
                            timeout: 5000,
                            position: 'right',
                        };
                        this._notification.show(config);
                    } else {
                        // Error notification
                        const config: NotificationConfig = {
                            content: failureString,
                            type: 'toast',
                            style: 'error',
                            timeout: 5000,
                            position: 'right',
                        };
                        this._notification.show(config);
                    }
                } catch (err) {
                    this.catchError(err);
                } finally {
                    this._spinner.removeOverlay();
                    this.unselectRows();
                }
            }
        });
    }

    async onCreateOrdersGroupDrawerClicked() {
        try {
            if (!this.newOrdersGroup!.formValid) {
                return;
            }

            this._spinner.show({ type: "spinner" });

            const result = await this.newOrdersGroup!.create();
            if (result) {
                if (typeof result === 'number') {
                    // Error notification
                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.group-error-save'),
                        type: 'toast',
                        style: 'error',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);
                } else {
                    // Collapse the drawer
                    this._drawer.collapse();

                    // Notification
                    const config: NotificationConfig = {
                        content: this._translate.instant('orders-list.group-saved', { code: result.code }),
                        type: 'toast',
                        style: 'check',
                        timeout: 5000,
                        position: 'right',
                    };
                    this._notification.show(config);

                    // Reload the planned orders list when a new group is created
                    await this.loadData(false);

                    // Unselect devices and orders
                    this.plannedOrders.forEach(order => order.selected = false);
                    this.plannedOrdersSelectedDevicesId = [];

                    // Reset the form
                    this.newOrdersGroup!.reset();
                }
            }
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async onEditOrdersGroupDrawerClicked() {
        try {
            if (!this.editOrdersGroup!.formValid) {
                return;
            }

            this._spinner.show({ type: "spinner" });

            const result = await this.editOrdersGroup!.update();
            if (result && result["status"] >= 200 && result["status"] <= 299) {
                // Collapse the drawer
                this._drawer.collapse();

                // Notification
                const config: NotificationConfig = {
                    content: this._translate.instant('orders-list.group-saved', { code: this.editOrdersGroup.group?.code }),
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);

                // Reload the planned orders list when a new group is created
                await this.loadData(false);

                // Reset the form
                this.editOrdersGroup!.reset();
            } else {
                // Error notification
                const config: NotificationConfig = {
                    content: this._translate.instant('orders-list.group-error-save'),
                    type: 'toast',
                    style: 'error',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            }
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    async onAddOrdersToGroupDrawerClicked() {
        try {
            if (!this.addOrdersToGroup?.selectedOrders || !this.addOrdersToGroup?.selectedGroup) {
                return;
            }

            this._spinner.show({ type: "spinner" });

            // List of order codes
            const orderCodes: string[] = this.addOrdersToGroup!.selectedOrders.map((order: OrderListInterface) => order.code);
            const translateArgs = { codes: orderCodes.join(', '), groupCode: this.addOrdersToGroup!.selectedGroup!.code };

            const result = await this.addOrdersToGroup!.add();
            if (result && result["status"] >= 200 && result["status"] <= 299) {
                // Collapse the drawer
                this._drawer.collapse();

                // Notification
                const config: NotificationConfig = {
                    content: this._translate.instant('orders-list.group-association.success', translateArgs),
                    type: 'toast',
                    style: 'check',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);

                // Reload the planned orders list when a bunch of orders are associated to a group
                await this.loadData(false);

                // Reset the form
                this.addOrdersToGroup!.reset();
            } else {
                // Error notification
                const config: NotificationConfig = {
                    content: this._translate.instant('orders-list.group-association.failure', translateArgs),
                    type: 'toast',
                    style: 'error',
                    timeout: 5000,
                    position: 'right',
                };
                this._notification.show(config);
            }
        } catch (err) {
            this.catchError(err);
        } finally {
            this._spinner.removeOverlay();
        }
    }

    private _plannedOrdersDevicesFilter(filterValue: unknown,
        selectedFilterOperation: ColumnFilterOperation,
        target: ColumnFilterTargetValues) {
        return ["deviceLabels", "contains", filterValue];
    }

    private catchError(error: string) {
        console.error(error);

        this._translate.stream([
            'orders-list.filters.error',
        ]).subscribe((translations) => {
            const config: NotificationConfig = {
                content: translations['orders-list.filters.error'],
                type: 'toast',
                style: 'error', 
                timeout: 5000,
                position: 'right',
            };
            this._notification.show(config);
        });
    }
    
    onDragChange: TsTreeListRowDraggingOnDragChange<OrderListInterfaceUi> = event => {
        this._drawer.collapse();

        const sourceNode = this.plannedOrdersList.getNodeByKey(event.rowData.id);
        let targetNode = this.plannedOrdersList.getVisibleRowByIndex(event.toIndex)?.node;
        // prevent actions if order is not changed
        while (targetNode?.data) {
            if (targetNode.data.ID === sourceNode?.data.ID) {
                event.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    };

    onReorder: TsTreeListRowDraggingOnReorder<OrderListInterfaceUi> = async event => {
        try {
            this._spinner.show({ type: "spinner" });
            const sourceData = event.rowData;
            const toIndex = event.fromIndex > event.toIndex ? event.toIndex - 1 : event.toIndex;
            const targetData = toIndex >= 0 ? this.plannedOrdersList.getVisibleRowByIndex(toIndex)?.node.data : null;
        
            const sourceIndex = this.plannedOrders.indexOf(sourceData);
            this.plannedOrders.splice(sourceIndex, 1);
        
            const targetIndex = this.plannedOrders.indexOf(targetData) + 1;
            this.plannedOrders.splice(targetIndex, 0, sourceData);
        
            // rebuild ranks
            const orderRank = this._recomputeRankOfList(sourceData.id, sourceData.groupId, sourceData.rank);
                
            const result = await this._ordersListService.editOrderRank(sourceData.isGroup ? null : sourceData.id, sourceData.isGroup ? sourceData.id : sourceData.groupId, orderRank);
            if (result && result["status"] == 200) {
                sourceData.rank = result["body"].rank;
            } else {
                throw new Error('Request failed');
            }
        
            this.plannedOrdersList.treeListComponent.instance.refresh();
        } catch(err) {
            this.catchError(err);
            await this.loadData(false);
        } finally {
            this._spinner.removeOverlay();
        }
    };

    async onRankChanged(data: {order: OrderListInterface, event: {target: {value: number}}}) {
        this._spinner.show({ type: "spinner" });
        await new Promise<void>(async (resolve) => {
            try {
                const order = data.order;
                const newRank = data.event.target.value;
                if (order.rank === newRank) {
                    return;
                }
                const increment = order.rank < newRank;
                for (let o of this.plannedOrders) {
                    if (o.groupId != order.groupId) {
                        continue;
                    }
                    if (increment && o.rank > order.rank && o.rank <= newRank) {
                        o.rank--;
                    } else if (!increment && o.rank < order.rank && o.rank >= newRank) {
                        o.rank++;
                    }
                }
                
                order.rank = newRank;
                this.plannedOrders.sort((a, b) => a.rank - b.rank);
                
                const result = await this._ordersListService.editOrderRank(order.isGroup ? null : order.id, order.isGroup ? order.id : order.groupId, newRank);
                if (result && result["status"] == 200) {
                    order.rank = result["body"].rank;
                }  else {
                    throw new Error('Request failed');
                }
                await this.plannedOrdersList.reload();
            } catch(err) {
                this.catchError(err);
                await this.loadData(false);
            } finally {
                this._spinner.removeOverlay();
                resolve();
            }
        });
    }

    private _recomputeRankOfList(orderId: number, groupId: number, orderRank?: number): number {
        let rank = 1;
        for (let order of this.plannedOrders) {
            if (order.groupId === groupId) {
                order.rank = rank;
                rank++;
            }
            if (order.id === orderId) {
                orderRank = order.rank;
            }
        }
        return orderRank;
    }

    private unselectRows() {
        // Unselect devices and orders
        this.plannedOrders.forEach(order => order.selected = false);
        this.plannedOrdersSelectedDevicesId = [];

        // Update tree list selection to render the rows as selected
        const ids = this.plannedOrdersSelected.map((order: OrderListInterface) => order.id);
        this.plannedOrdersList.selectRows(ids, false);
    }

    private updateCheckBoxState() {
        if (this.plannedOrdersSelected?.length > 0) {
            const order: OrderListInterface = this.plannedOrdersSelected[0];

            if (order.groupId) {
                // When a child is selected: only children of that group can be selected
                this.plannedOrders.forEach((currentOrder: OrderListInterface) => {
                    currentOrder.checkBoxEnabled =
                        !currentOrder.isGroup &&
                        currentOrder.groupId === order.groupId;
                });
            } else {
                // Orders that are not in a group together: only orders assigned to the
                // same devices can be selected
                this.plannedOrders.forEach((currentOrder: OrderListInterface) => {
                    const devicesId = currentOrder.Devices?.map(device => device.id);
                    currentOrder.checkBoxEnabled =
                        !currentOrder.isGroup &&
                        !currentOrder.groupId &&
                        isEqual(this.plannedOrdersSelectedDevicesId, devicesId);
                });
            }
        } else {
            // When no order is selected: all checkbox are enabled
            this.plannedOrders?.forEach((currentOrder: OrderListInterface) => {
                // Groups cannot be selected
                currentOrder.checkBoxEnabled = !currentOrder.isGroup;
            });
        }
    }

    private tooltipClickHandler = (e: Event) => {
        if (this.lastShowNewOrderOptions) {
            this.showNewOrderOptions = false;
        }
        this.lastShowNewOrderOptions = this.showNewOrderOptions;
    };

    parseDate(date: any): string {
        const local = localStorage.getItem('lang') || 'en';
        return local === 'en' ? moment(date).format('MM/DD/YYYY HH:mm') : moment(date).format('DD/MM/YYYY HH:mm');
    }

}
