<div class="devices-page">
    <div class="devices-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'devices.title' | translate}}</h2>
            </div>
            <div class="column" *ngIf="plants?.length > 1 || isAdmin">
                <div class="right">
                    <ng-container>
                        <ts-select [data]="plants" [value]="selectedPlant?.description" labelKey="description"
                                   (valueChange)="plantChanged($event)" [clearable]="false"
                                   class="plant-selector"></ts-select>
                    </ng-container>
                    <div class="button-group" *ngIf="isAdmin">
                        <ts-button icon="pencil" (buttonClicked)="onCompanyActionClick()"></ts-button>
                        <ts-button icon="plus" (buttonClicked)="newPlantAction()"></ts-button>
                    </div>
                </div>
            </div>
        </div>

        <ng-container>
            <div class="ts-vapor ts-layout layout-container">

                <ts-tree-list #devicesList [data]="tmpDevices" [columns]="devicesColumns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="devicesListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                        <span *ngIf="tplKey === 'tplActions'">

                            <ts-tree-list-button *ngIf="isAdmin" icon="wrench"
                                                 (click)="onCompanyActionClick(data)"></ts-tree-list-button>
                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="onActionClick(data, true)"></ts-tree-list-button>
                            <ts-tree-list-button icon="qrcode" title="QR Code"
                                                 (click)="onActionClick(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #editDeviceDrawerTemplate>
    <app-devices-drawer #devicesDrawer [device]="selectedDevice" [isEditing]="true"
                        [isAdmin]="isAdmin"></app-devices-drawer>
</ng-template>

<ng-template #showDeviceQRDrawerTemplate>
    <app-devices-drawer #devicesDrawer [device]="selectedDevice" [isEditing]="false"></app-devices-drawer>
</ng-template>

<ng-template #companyDeviceDrawerTemplate>
    <app-company-drawer #companyDrawer [deviceModule]="selectedDevice.DeviceModule" [deviceId]="selectedDevice.id"
                        [isDevice]="true" type="device"></app-company-drawer>
</ng-template>

<ng-template #companyPlantDrawerTemplate>
    <app-company-drawer #companyDrawer [plantModule]="selectedPlant.PlantModule"
                        [plantDescription]="selectedPlant.description" [isDevice]="false"
                        type="plant"></app-company-drawer>
</ng-template>

<ng-template #newCompanyPlantTemplate>
    <app-company-drawer #companyDrawer [plantModule]="newPlant?.PlantModule" [plantDescription]="newPlant.description"
                        [isDevice]="false" type="new-plant"></app-company-drawer>
</ng-template>

<ng-template #devicesDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar type="medium" exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="save()"
                   [disableExit]="!devicesDrawer?.formValid">
        <ts-button type="secondary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #companyDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar type="medium" exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onCompanyFormSubmit()"
                   [disableExit]="!companyDrawer?.formValid">
        <ts-button type="secondary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>

        <ts-button type="tertiary blue small" text="Reset Config" *ngIf="companyDrawer?.isDeviceModule"
                   (buttonClicked)="deleteDeviceModule()"></ts-button>
    </ts-bottom-bar>
</ng-template>