import { Injectable } from '@angular/core';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ConfigService {
    public API: {
        baseUrl: string,
        url: string,
        urlV2: string
    };
    public tsidEnabled: boolean;

    constructor(private _envSettings: EnvConfigurationService) {
        this.API = {
            baseUrl: this._envSettings.settings.api.baseUrl,
            url: this._envSettings.settings.api.url,
            urlV2: this._envSettings.settings.api.urlV2
        };
        this.tsidEnabled = this._envSettings.settings.tsidEnabled;
    }

    public getCookieDomain(): string {
        const host = window.location.hostname;
        
        if (host === 'localhost') {
          return 'localhost';
        }
      
        const parts = host.split('.');
      
        if (parts.length <= 2) {
          return host;
        }

        return `.${parts.slice(1).join('.')}`;
    }
}
