<div class="date-time-picker">
    <div [style.max-width]="width" [style.width]="width" [id]="id">
        <div (click)="!modalIsOpen ? onModalOpen() : onModalClose()" class="pickerIconMain"><span><svg role="img" aria-hidden="true" focusable="false"
                        data-prefix="far" data-icon="calendar" class="svg-inline--fa fa-calendar fa-lg"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="#0076AD"
                            d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z">
                    </path>
                </svg>
            </span></div>
        <ts-text-box 
            [showErrors]="true" 
            width="width" 
            [(ngModel)]="dateTimeString"
            primaryText="{{primaryText}}" 
            [readOnly]="true"
            (click)="!modalIsOpen ? onModalOpen() : onModalClose()">
        </ts-text-box>
    </div>

    <div *ngIf="modalIsOpen">
        <div class="wrapper">
            <div class="container" (click)="$event.stopPropagation()">
                <div class="modalHeader">
                    <div class="modalHeaderClose">
                        <span (click)="onModalClose()">
                            <svg role="img" aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" class="svg-inline--fa fa-xmark fa-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z">
                                </path>
                            </svg>
                        </span>
                    </div>
                    <span>{{modalTitle}}</span>
                </div>
                <div class="modalBody" [id]="id">
                    <div [style.min-width]="width" [style.width]="width">
                        <vapor-angular-datepicker 
                            (valueChange)="setDate($event)" 
                            [value]="getDate()"
                            width="inputWidth">
                        </vapor-angular-datepicker>
                    </div>
                    <div [style.min-width]="width" [style.width]="width">
                        <vapor-angular-timepicker
                            (valueChange)="setTime($event)" 
                            [value]="getTime()"
                            width="inputWidth">
                        </vapor-angular-timepicker>
                    </div>
                </div>
                <ts-button 
                    type="primary blue medium" 
                    text="{{'orders-list.confirm-button' | translate}}"
                    (buttonClicked)="onConfirm()"></ts-button>
            </div>
        </div>
    </div>
</div>
